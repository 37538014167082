<template>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Search',
    components: {
    },
    data() {
        return {
          items: [],
          query: '',
          authors:[],
	      rate_amount:1,
	      api_baseurl:"",
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Search'

        let uri = window.location.search.substring(1)
        let params = new URLSearchParams(uri)

        if (params.get('query')) {
            this.query = params.get('query')

            this.performSearch()
        }
    },
    methods: {
       loadPrev: function () {
    	 this.currentPage -=1
    	 this.getAssets()
        },
        loadNext: function () {
    	  this.currentPage +=1
    	  this.getAssets()
        },
   	    goto(url){
		  window.location.href = url
	    },
        async performSearch() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/search/?query='+this.query, {'query': this.query})
                .then(response => {
                    this.items = response.data.items
                    console.log(response.data.items)
                    this.authors = response.data.authors
                    console.log(response.data.authors)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>