import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
//import ElementPlus from 'element-plus'
//import 'element-plus/dist/index.css'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import i18n from './i18n'

//import 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css' //boostarp
/*
import './assets/mandalahouse/css/bootstrap.min.css'
import './assets/mandalahouse/css/font-awesome.min.css'
import './assets/mandalahouse/css/ionicons.min.css'
import './assets/mandalahouse/css/themify-icons.css'
import './assets/mandalahouse/css/plugins.css'
import './assets/mandalahouse/css/helper.css'
import './assets/mandalahouse/css/main.css'
//import './assets/mandalahouse/revolution/css/settings.css'
import './assets/mandalahouse/revolution/css/navigation.css'
import './assets/mandalahouse/revolution/custom-setting.css'
//import './assets/mandalahouse/js/vendor/modernizr-2.8.3.min.js'

import './assets/mandalahouse/js/vendor/jquery.min.js'
import './assets/mandalahouse/js/popper.min.js'
import './assets/mandalahouse/js/bootstrap.min.js'
import './assets/mandalahouse/js/plugins.js'
import './assets/mandalahouse/js/main.js'
import './assets/mandalahouse/revolution/js/jquery.themepunch.revolution.min.js'
//import './assets/mandalahouse/revolution/js/jquery.themepunch.tools.min.js'
import './assets/mandalahouse/revolution/revolution-active.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.kenburn.min.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.slideanims.min.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.actions.min.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.layeranimation.min.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.navigation.min.js'
import './assets/mandalahouse/revolution/js/extensions/revolution.extension.parallax.min.js'
*/
//Live
axios.defaults.baseURL = 'https://api.trungdinh.com'
//Dev 
//axios.defaults.baseURL = 'http://127.0.0.1:1008'
/*
createApp({
  extends: App,
  mixins: [gMixin],
  components: {
  }
}).use(store).use(router, axios).mount('#app')*/

const app = createApp(App).use(i18n)
app.mixin({
  methods: {
    truncate: function (text, lstring, suffix) {
	  	if (undefined !== text && text.length > lstring) {
	    	return text.substring(0, 5) + suffix +text.substring(text.length-5);
	    	//return text;
	   	} else {
	    	return text;
	  	}
  	},
	convertCrypto: function (value, inCurrency, toCurrency) {
	  	if (undefined !== value && value > 0) {
			let inCurrencyRate = this.$store.state.rate.rates[inCurrency];
			let toCurrencyRate = this.$store.state.rate.rates[toCurrency]
			return (value*toCurrencyRate/inCurrencyRate).toFixed(14);
	   	} else {
	    	return value;
	  	}
  	},
	verifyToken: function() {
			console.log("calling verifyToken...");
            let aToken = JSON.parse(localStorage.getItem("accessToken"));
    		let reToken = JSON.parse(localStorage.getItem("refreshToken"));
			console.log(aToken);
			console.log(reToken);
    		const verifyData = {
            	token: aToken,
            }
            axios
                .post("/api/metamask/token/verify/", verifyData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                })
                .catch(error => {
					const refreshData = {
            	refresh: reToken,
            }
           axios
                .post("/api/metamask/token/refresh/", refreshData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    this.$store.commit('setToken', response.data.access);
                    this.$store.commit('setAccessToken',response.data.access)
					axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
                })
                .catch(error => {
					
                })
                })
    	},
	verifyToken: function(acToken,reToken) {
			console.log("calling verifyToken...");
            console.log(acToken);
			console.log(reToken);
    		const vData = {
            	token: acToken,
            }
            axios
                .post("/api/metamask/token/verify/", vData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                })
                .catch(error => {
					const refreshData = {
	            	refresh: reToken,
	            }
	           axios
	                .post("/api/metamask/token/refresh/", refreshData)
	                .then(response => {
	                	console.log(response)
	                    console.log(response.data)
	                    this.$store.commit('setToken', response.data.access);
	                    this.$store.commit('setAccessToken',response.data.access)
						axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
	                })
	                .catch(error => {
						
	                })
                })
    	},
	/*
	  refreshToken: function(reToken) {
			console.log("calling acToken...");
            
			const refreshData = {
            	refresh: reToken,
            }
           axios
                .post("/api/metamask/token/refresh/", refreshData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    this.$store.commit('setToken', response.data.access);
                    this.$store.commit('setAccessToken',response.data.access)
					axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
                })
                .catch(error => {
					
                })
    	},*/
  }
})
app.use(VueLoading)
app.use(store).use(router, axios).mount('#app')

