<template>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ItemCategory',
    components: {
    },
    data() {
        return {
            category: {
                products: []
            },
            itemcategories:[],
        }
    },
    mounted() {
        this.getCategoryList()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'ItemCategory') {
                this.getCategoryList()
            }
        }
    },
    methods: {
        async getCategoryList() {
            this.$store.commit('setIsLoading', true)
            axios
                .get('/api/itemcategories/')
                .then(response => {
                	console.log(response.data.results);
                    this.itemcategories = response.data.results;
                    document.title = 'All Categories';
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>