<template>
</template>
<script>
import axios from 'axios'

export default {
    name: 'DiscoverAsset',
    mounted() {
        document.title = 'Discover Assets'
        this.getAssets()
        this.rate_amount=this.$store.state.rate.amount
        this.api_baseurl= axios.defaults.baseURL
    },
    data() {
      return {
	      assets:[],
	      rate_amount:1,
	      api_baseurl:"",
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
     }
   },
   methods: {
   	  loadPrev: function () {
    	this.currentPage -=1
    	this.getAssets()
      },
      loadNext: function () {
    	this.currentPage +=1
    	this.getAssets()
      },
   	  goto(url){
		window.location.href = url
	  },
      async getAssets() {
      	this.$store.commit('setIsLoading', true)
	  		await axios
	                .get("/api/items/?image_sizes=550x590&user_image_sizes=30x30&page="+this.currentPage+"&page_size="+this.page_size)
	                .then(response => {
						console.log(response.data.results)
						this.assets=response.data.results
						this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
						if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    this.$store.commit('setIsLoading', false)
	                })
	                .catch(error => {
	                	this.$store.commit('setIsLoading', false)
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	    	
	  	},
   }
}
</script>