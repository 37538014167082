<template>
</template>

<script>
import config from '../config.js'
import axios from 'axios'
import Toast from '@/components/Toast.vue'
import VueMetamask from 'vue-metamask'

export default {
    name: 'AuthorProfile',
    components: {
  		VueMetamask,
  	},
    data() {
	    return {
	      itemList: [],
	      count:0,
	      currentPage:1,
	      page_size:8,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
	      user:{},
	      metamaskWallet:{},
	      metamask_address:"",
	      api_baseurl:"",
	      rate_amount:1,
	    }
	  },
	beforeCreate() {
    	this.$store.commit('initializeStore')
    	const token = this.$store.state.token
	    if (token) {
	        //axios.defaults.headers.common['Authorization'] = "Token " + token
	        axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    } else {
	        axios.defaults.headers.common['Authorization'] = ""
	    }
    },
    mounted() {
    	document.title = 'Author Profile';
    	this.api_baseurl= axios.defaults.baseURL;
    	this.getUser();
    	//this.getItemList();
        this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
        console.log(this.metamaskWallet);
        this.metamask_address = this.metamaskWallet['metaMaskAddress'];
        console.log(this.metamask_address);
        this.rate_amount=this.$store.state.rate.amount
    },
    methods: {
        async allowBuy(tokenId,price) {
        	//const API_URL="https://eth-ropsten.alchemyapi.io/v2/mkmpYPZHBR0zola6xOzx4A8E5XCcrbcI"
        	const API_URL=config.apiUrl
        	const MYWALLET_ADDRESS = this.metamaskWallet['metaMaskAddress']
        	const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			
			const ETH_PRICE = (1000000000000000000*price/this.$store.state.rate.amount).toFixed(0).toString();
			
			const contract = require("../../artifacts/contracts/VONMIVERSE.sol/VONMIVERSE.json")
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(PUBLIC_KEY, "latest") //get latest nonce
			
			nftContract.methods.allowBuy(tokenId,ETH_PRICE).send({from:MYWALLET_ADDRESS});
			
        },
        onComplete(data){
	    	console.log('data:', data);
	    	console.log(data['metaMaskAddress']);
	    	if(!data['metaMaskAddress']) {
	    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
	    		
	    	}
	    	this.$store.commit('setMetamaskWallet', data);
	    	this.metamask_address=data['metaMaskAddress']
	    },
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getItemList()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getItemList()
    	},
    	async getUser() {
    		const uuid = this.$route.params.uuid
    		this.$store.commit('setIsLoading', true)

            axios
                .get('/api/users/'+uuid+'/?image_sizes=65x65')
                .then(response => {
                	console.log(response.data)
                    this.user = response.data
                    this.getItemList();
                    document.title = this.user.full_name 
                })
                .catch(error => {
                    console.log(error)
                })

           this.$store.commit('setIsLoading', false)
    	},
    	async getItemList() {
    		const user_uuid = this.$route.params.uuid
    		
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
			if (!this.currentPage) {
				this.currentPage = 1
			} 
			this.$store.commit('setIsLoading', true)
			console.log(this.user.id)
			
	        await axios.get("/api/items/?group=trending&image_sizes=550x590&user_id="+this.user.id+"&page="+this.currentPage+"&page_size="+this.page_size)
	                .then(response => {
	                	console.log(response.data.count == 0)
	                	console.log(response.data.count)
	                	if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	console.log(response.data);
	                	console.log(response.data.results);
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	this.$store.commit('showToast', "Get item list");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.itemList=response.data.results;
	                    if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    localStorage.setItem("itemList", response.data.results);
	                    this.$store.commit('setIsLoading', false)     
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	        })
	           
    		this.$store.commit('showToast', "Get Item List...");
    		
    	},
    }
}
</script>