<template>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            email: '',
            errors: []
        }
    },
    mounted() {
        document.title = 'Forgot Password'
    },
    methods: {
  		async submitForm() {
  			const formData = {
                email: this.email
            }
            
  			await axios
                .post("/api/rest-auth/password/reset/",formData)
                .then(response => {
                	
		            this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
}
</script>