<template>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Class',
    components: {
    },
    data() {
        return {
        	isLoading: false,
            fullPage: false,
            object: {}
        }
    },
    mounted() {
        this.getClass()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Class') {
                this.getClass()
            }
        }
    },
    methods: {
    	doAjax() {
        	this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                    this.isLoading = false
                }, 5000)
        },
        onCancel() {
        	console.log('User cancelled the loader.')
        },
        async getClass() {
            const classSlug = this.$route.params.class_slug
			
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                    loader:'dots',
                });
            axios
                .get(`/api/classes/${classSlug}/`)
                .then(response => {
                    this.object = response.data
                    document.title = this.object.name
                    setTimeout(() => {
	                    loader.hide()
	                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>