<template>
<div class="breadcrumbs-wrapper">
        <div class="breadcrumbs">
            <div class="row">
                <ul class="items col-md-12">
                	<li class="item home">
                     	<a href="/" title="Trang chủ">Trang chủ</a>
                   	</li>
              		<li class="item cms_page">
                    	<strong>{{ post.title }}</strong>
             		</li>
            	</ul>
            </div>
        </div>
    </div>		
	<main id="maincontent" class="page-main">    
		<div class="columns">
			<div class="box-page">
				<div class="sidebar sidebar-main">
					<ul>
						<li v-for="category in post.categories"><a :href="'/posts/category/'+category.slug+'/'">{{category.title}}</a></li>
						<li v-if="post.type"><a :href="'/posts/type/'+post.type.slug+'/'">{{post.type.title}}</a></li>
					</ul>
				</div>
				<div class="box-page-content">
					<div class="box-page-content-inner">
						<h1>{{ post.title }}</h1>
						<div v-html="post.content"/>
						<hr/>
						<div class="row">
							<div class="col-lg-12 col-md-12">
								<h3>Đặt Lịch</h3>
							</div>
							<div class="col-lg-12 col-md-12">
								<div class="form-group">
									<input type="text" name="fullname" id="fullname" class="form-control" ref="fullname" placeholder="Nhập họ tên">
								</div>
								<div class="form-group">
									<input type="text" name="email" id="email" class="form-control" ref="email" placeholder="Thư điện tử">
								</div>
								<div class="form-group">
									<input type="text" name="phone" id="phone" class="form-control" ref="phone" placeholder="Số điện thoại">
								</div>
								<div class="form-group">
									<input type="text" name="address" id="address" class="form-control" ref="address" placeholder="Địa chỉ">
								</div>
								<div class="form-group">
									<textarea name="note" class="form-control" id="note" ref="note" cols="30" rows="5" placeholder="Lưu ý"></textarea>
								</div>
								<input type="hidden" name="content_type" id="content_type" class="form-control" ref="content_type" value="29">
								<input type="hidden" name="object_id" id="object_id" class="form-control" ref="object_id" :value="post.id">
								<div class="form-group">
									<button type="submit" class="btn-cart lg-btn"  v-on:click="bookAService"><span>Đặt</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Post',
    components: {
    },
    data() {
        return {
            post: {},
            api_baseurl:"",
            fullPage:false,
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getPost()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Post') {
                this.getPost()
            }
        }
    },
    methods: {
    	async bookAService() {
    		const user = JSON.parse(localStorage.getItem("user"));
    		const serviceFormData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
	    console.log(serviceFormData)
	    await axios
                .post("/api/objectregistrations/", serviceFormData)
                .then(response => {
                	console.log(response.data);
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
        async getPost() {
            const postSlug = this.$route.params.post_slug

			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
            });
            
            axios
                .get(`/api/posts/${postSlug}/`)
                .then(response => {
                    this.post = response.data
                    document.title = this.post.title 
                    setTimeout(() => {
		            	loader.hide()
		            }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>