import { createRouter, createWebHistory } from 'vue-router'
import VueBodyClass from 'vue-body-class'
import store from '../store'

import Home from '../views/Home.vue'
import LayoutHome from '../layouts/LayoutHome.vue'
import LayoutDefault from '../layouts/LayoutDefault.vue'
import LayoutItem from '../layouts/LayoutItem.vue'
import LayoutPage from '../layouts/LayoutPage.vue'
import LayoutClass from '../layouts/LayoutClass.vue'
import LayoutCheckout from '../layouts/LayoutCheckout.vue'
import NotFound from '../views/404.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import Success from '../views/Success.vue'
import Checkout from '../views/Checkout.vue'
import Payment from '../views/Payment.vue'
import PaymentMethod from '../views/PaymentMethod.vue'
import Post from '../views/Post.vue'
import Page from '../views/Page.vue'
import Class from '../views/Class.vue'
import Product from '../views/Product.vue'
import Item from '../views/Item.vue'
import TokenUri from '../views/TokenUri.vue'
import Category from '../views/Category.vue'
import ItemList from '../views/ItemList.vue'
import CategoryAll from '../views/CategoryAll.vue'
import AuthorProfile from '../views/AuthorProfile.vue'
import MyProfile from '../views/MyProfile.vue'
import MyProfileEdit from '../views/MyProfileEdit.vue'
import CreateCollection from '../views/CreateCollection.vue'
import Collection from '../views/Collection.vue'
import AuthorList from '../views/AuthorList.vue'
import AllNFTList from '../views/AllNFTList.vue'
import LiveAuction from '../views/LiveAuction.vue'
import DiscoverAsset from '../views/DiscoverAsset.vue'
import Activity from '../views/Activity.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import PostList from '../views/PostList.vue'
import ClassList from '../views/ClassList.vue'
import AddWallet from '../views/AddWallet.vue'
import MintNFT from '../views/MintNFT.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: LayoutHome,bodyClass:'' },
  },
  {
    path: '/contact-us/',
    name: 'Contact Us',
    component: Contact,
    meta: { layout: LayoutDefault, bodyClass:'' },
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/login/',
    name: 'LogIn',
    component: LogIn,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/forgot-password/',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/my-account/',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
 {
    path: '/add-wallet/',
    name: 'Add Wallet',
    component: AddWallet,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/posts/',
    name: 'PostList',
    component: PostList,
    meta: { layout: LayoutDefault, bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-1column' },
  },
   {
    path: '/classes/',
    name: 'ClassList',
    component: ClassList,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/profile/',
    name: 'My Profile',
    component: MyProfile,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/profile/edit/',
    name: 'Edit My Profile',
    component: MyProfileEdit,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/profile/:uuid/',
    name: 'Author Profile',
    component: AuthorProfile,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/authors/',
    name: 'Author List',
    component: AuthorList,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/activity/',
    name: 'Activity',
    component: Activity,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/all-nfts/',
    name: 'All NFTs',
    component: AllNFTList,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/discover-assets/',
    name: 'Discover Assets',
    component: DiscoverAsset,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/live-auction/',
    name: 'Live Auction',
    component: LiveAuction,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/create-collection/',
    name: 'Create Collection',
    component: CreateCollection,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/mint-nft/',
    name: 'Mint NFT',
    component: MintNFT,
    meta: {
        requireLogin: true,
        layout: LayoutDefault 
    }
  },
  {
    path: '/collection/',
    name: 'Collection',
    component: Collection,
    meta: { layout: LayoutDefault },
  }, 
  {
    path: '/search/',
    name: 'Search',
    component: Search,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/cart/',
    name: 'Cart',
    component: Cart,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/cart/success/',
    name: 'Success',
    component: Success,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/cart/start-payment/',
    name: 'Payment',
    component: Payment,
    meta: {
        requireLogin: true,
        layout: LayoutCheckout,bodyClass:'checkout' },
  },
  {
    path: '/checkout/payment-method/',
    name: 'PaymentMethod',
    component: PaymentMethod,
    meta: {
        requireLogin: true,
        layout: LayoutCheckout,bodyClass:'checkout' },
  },
  {
    path: '/cart/checkout/',
    name: 'Checkout',
    component: Checkout,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/posts/:post_slug/',
    name: 'Post',
    component: Post,
    meta: { layout: LayoutDefault,bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-2columns-left' },
  },
  {
    path: '/pages/:page_slug/',
    name: 'Page',
    component: Page,
    meta: { layout: LayoutPage, bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-2columns-left' },
  },
   {
    path: '/classes/:class_slug/',
    name: 'Class',
    component: Class,
    meta: { layout: LayoutClass,bodyClass:'' },
  },
  {
    path: '/tokenuris/:tokenuri_slug/',
    name: 'TokenUri',
    component: TokenUri,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/items/:slug/',
    name: 'Item',
    component: Item,
    meta: { layout: LayoutItem,bodyClass:'page-product-configurable catalog-product-view page-layout-1column' },
  },
  {
    path: '/posts/category/:category_slug/',
    name: 'Category',
    component: Category,
     meta: { layout: LayoutDefault },
  },
{
    path: '/items/',
    name: 'ItemList',
    component: ItemList,
    meta: { layout: LayoutDefault,bodyClass:'page-with-filter page-products catalog-category-view page-layout-1column' },
  },
{
    path: '/items/category/all/',
    name: 'CategoryAll',
    component: CategoryAll,
    meta: { layout: LayoutDefault },
  },
  {
    path: "/:catchAll(.*)/",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
      layout: LayoutDefault,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
