// In your config.js file
export default {
   hostname: 'hostname',
    contractAddress:'0x10B8AEC2dEcC76c936B609b2F64c5884Ae3b1eC3', //live polygon
	//contractAddress:'0xd2D388651a40F3D68f674033c3eFCa2202e376A0',//test polygon
    //contractAddress:'0xa26da1a6e3FAfA21d630cB223F85662896a4C80e',//test ropsten
	//contractAddress:'0x4Ac0dDd34009fEAE466970D4aE129DD72A37014c',//test ropsten
   	apiUrl:'https://polygon-mainnet.g.alchemy.com/v2/Q2a2n82mCVJMxibjw0Se-H3sjA51LwKI', //live polygon
	//apiUrl:'https://polygon-mumbai.g.alchemy.com/v2/Z2eTuf_lAY7zwuDlu75CeoD-CZ-B5uof',
	//apiUrl:'https://eth-ropsten.alchemyapi.io/v2/L6DL1hRnnw_P9FeVojb-qaOxfJUbmiLU',
	explorerUrl:'https://polygonscan.com/',//live polygon
	//explorerUrl:'https://ropsten.etherscan.io/',//test ropsten
	//explorerUrl:'https://mumbai.polygonscan.com/',
}
