<template>
<div class="breadcrumbs">
    <ul class="items">
        <li class="item"><a href="/">Trang chủ</a></li>
        <li class="item" v-if="item.category"><a :title="item.category" href="/dien-thoai">Điện thoại</a></li>
        <li class="item" v-else><a title="Danh mục" href="/items/category/all/">Danh mục</a></li>
	</ul>
</div>

<main id="maincontent" class="page-main">
<div class="columns">
	<div class="row">
		<div class="content-wrapper">
			<div class="column main">
				<div class="maincontents row">
					<div class="mainwrapper col-md-9">
						<div class="block-section">
							<div class="product-info-main">
								<div class="heading-title">
									<h1>{{item.title}}</h1>
								</div>
								<span class="product-reviews-summary"> 
						            <span itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
						            <meta itemprop="ratingValue" content = "4.5">
						            <meta itemprop="ratingCount" content = "303">
						        </span>
					         	<div class="rating-summary review-rating">
					        		<i class="fa star-full"></i>
					        		<i class="fa star-full"></i>
					        		<i class="fa star-full"></i>
					        		<i class="fa star-full"></i>
					                <i class="fa star-empty"></i>
					             </div>
        						<!--span class="reviews-actions-intro product attribute review"><a id="viewmoreallreview" class="action view" href="#">Hỏi đáp</a></span--> 
    							<span class="reviews-actions-intro" v-if="item.upc">No: {{item.upc}}</span></span>

								<div id="product-link-related">
									<div class="grid-related">
								 		<div class="item-related ">
								 			<div class="inner">
								 				<a  class="current-active" href="/" :title="item.title">
								 					<span><i></i></span>
										 			<strong>
										 			<span class="price-related" v-if="item.price>0">{{item.price}} {{item.currency_symbol}}</span>
										 			<span class="price-related" v-else>Liên hệ</span>
										 			</strong>
										 		</a>
										 	</div>
									 	</div>
									</div>
								</div>
		<div class="product-add-form">
		    <div class="tocart-form-data"
		         data-name="iPhone 13 128GB Chính Hãng (VN/A)"
		         data-price="19.390.000 ₫"
		         data-image="https://cdn.trungdinh.com/pub/media/catalog/product/cache/39355d7f22ff5096f13e71fb0fd65cb0/i/p/iphone-13-128gb-didongviet_1.jpg"
		    ></div>
		    <form data-product-sku="3000000000102"
		          action="https://trungdinh.com/checkout/cart/add/uenc/aHR0cHM6Ly9kaWRvbmd2aWV0LnZuL2lwaG9uZS0xMy0xMjhnYi5odG1s/product/13599/" method="post"
		          id="product_addtocart_form">
		        <input type="hidden" name="product" value="13599" />
		        <input type="hidden" name="selected_configurable_option" value="" />
		        <input type="hidden" name="related_product" id="related-products-field" value="" />
		        <input type="hidden" name="item"  value="13599" />
		        <input name="form_key" type="hidden" value="8ruhEOb52PnBIQX4" />                                    
		                    <div class="product-options-wrapper" id="product-options-wrapper" data-hasrequired="&#x2A;&#x20;Required&#x20;Fields">
		    <div class="fieldset" tabindex="0">
		
		
		
				<div class="field configurable required">
		            <label class="label label_supper_attr" for="attribute93">
		                <span>Màu<span class="price"></span></span>
		            </label>
		            <div class="control-option">
		                   		<label style="display:none;" data-sku="3000000000105" onclick="showImage(this);newloadNhanhProduct(this,'50000101',13599);"  data-id="50000101" data-title="Màu đen " class="opt_attr" data-price="19.390.000 ₫" data-marketprice="24.190.000 ₫" data-discount="19" for="opt_attr_13">
		                            <input id="opt_attr_13" type="radio" value="13" name="super_attribute[93]">
		                            <span class="name"><i class="tick"></i>Màu đen </span>
		                            <span class="price"></span>
		                            
		                            <!-- <i class="fa fa-check"></i>-->
		                        </label>
		               <!-- <select name="super_attribute[93]"
		                        data-selector="super_attribute[93]"
		                        data-validate="{required:true}"
		                        id="attribute93"
		                        class="super-attribute-select">
		                    <option value="">Choose an Option...</option>
		                </select>-->
		            </div>
		        </div>
		    </div>
		</div>
		<div class="product-info-price" style="display:none;">
			<div>
		    	<div class="product-price-top">
		            <div class="price-box price-final_price" data-role="priceBox" data-product-id="13599" data-price-box="product-id-13599"><span class="normal-price">
						<span class="price-container price-final_price tax weee" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
		            <span class="price-label">Mua ngay</span>
			        <span id="product-price-" data-price-amount="" data-price-type="finalPrice" class="price-wrapper ">
			        	<span class="price"></span>        
			        </span>
		        <div class="old-price-info" style="display: none;">
		            <span class="percent-discount"></span>
		            <span class="price-old"></span>
		        </div>
		        <link itemprop="url" :href="'https://trungdinh.com/items/'+item.slug+'/'" />
		        <meta itemprop="availability" content="https://schema.org/InStock" />
		        <!-- meta itemprop="priceValidUntil" content="2023-02-01" /-->
		        <meta itemprop="price" :content="item.price" />
		        <meta itemprop="priceCurrency" :content="item.currency_code" />
		    </span>
		   </span>
		
		</div>
		<div class="wrapper-or">
		    <div class="line"></div>
		    <div class="wordwrapper">
		        <div class="word">Hoặc</div>
		    </div>
		</div>
		<div class="installment-wraper" style="display:none;">
		    <div class="installment-content">
		        <span class="installment-title">Trả trước từ</span>
		         <div class="price-box-installment">
		            <span class="price-installment"> 
		            {{item.price}} {{item.currency_symbol}}<span class="month"></span>
		            </span>
		        </div>
		  
		    </div>
		</div>
		<hr class="line">
	</div>
</div>     
<div class="product-promotion-wraper" style="display:none;">
	<section class="promotion-wrapper">
	    <div class="promotion-info">
	        <ul class="promotion-details">
	            <span class="pro-title text-red"><i class="fa fa-pro"></i></span>
	            <ul class="promotion-main">
					<li></li>
				</ul>        
			</ul>
	        <hr class="line">
	        <ul class="promotion-global">
                <div class="box-promotion-accessories">
	                <h4 class="total-accessories"><strong></strong></h4>
	                <ul>
						<li></li>
					</ul>
				</div>            
			</ul>
		</div>
	</section> 
</div>
<div class="payment-info-wrapter" style="display:none;">
	<div class="type-1"></div>
 	<div class="type-2"></div>
</div>
</div>
<div class="product-options-bottom">
    <div class="box-tocart">
    	<div class="fieldset">
        	<div class="field qty">
            	<label class="label" for="qty"><span>Số lượng</span></label>
	            <div class="control">
	                <span class="qty-action sub-action"><i class="fas fa-minus"></i></span>
	                <input type="number"
	                       name="qty"
	                       id="qty"
	                       min="0"
	                       value="1"
	                       title="Số lượng"
	                       class="input-text qty"
	                       data-validate="{&quot;required-number&quot;:true,&quot;validate-item-quantity&quot;:{&quot;minAllowed&quot;:1,&quot;maxAllowed&quot;:10000}}"
	                       />
	                <span class="qty-action add-action"><i class="fas fa-plus"></i></span>
	            </div>
        	</div>
          	<div class="actions col-md-12">
          		<div class="row">
							<div class="col-lg-12 col-md-12">
								<h3>Nhập thông tin của bạn</h3>
							</div>
							<div class="col-lg-12 col-md-12">
								<div class="form-group">
									<input type="text" name="fullname" id="fullname" class="form-control" ref="fullname" placeholder="Nhập họ tên">
								</div>
								<div class="form-group">
									<input type="text" name="email" id="email" class="form-control" ref="email" placeholder="Thư điện tử">
								</div>
								<div class="form-group">
									<input type="text" name="phone" id="phone" class="form-control" ref="phone" placeholder="Số điện thoại">
								</div>
								<div class="form-group">
									<input type="text" name="address" id="address" class="form-control" ref="address" placeholder="Địa chỉ">
								</div>
								<div class="form-group">
									<textarea name="note" class="form-control" id="note" ref="note" cols="30" rows="5" placeholder="Lưu ý"></textarea>
								</div>
								<input type="hidden" name="content_type" id="content_type" class="form-control" ref="content_type" :value="item.content_type_id">
								<input type="hidden" name="object_id" id="object_id" class="form-control" ref="object_id" :value="item.id">
								<div class="form-group">
									<button type="submit" title="Đặt ngay" class="action primary tocart" id="product-addtocart-button" v-on:click="bookAService">
						                <span>Đặt ngay</span>
						                <p>Giao hàng tân nơi hoặc nhận tại của hàng</p>
						            </button>
								</div>
								
							</div>
				</div>
				<div id="instant-purchase" data-bind="scope:'instant-purchase'"></div>
        	</div>
    	</div>
	</div>
</div>
<div class="button-installment pay-actions d-flex">
	<div class="col-installment bnt-action col-left">
        <a rel="nofollow" data-target="cty" class="button-checkout-installment btn-add-installment btn-go-cart" href="https://trungdinh.com/pages/mua-hang-tra-gop/">
            <span class="title">Trả góp</span>
            <span>Duyệt nhanh qua điện thoại</span>
        </a>
    </div>
    <div class="col-installment bnt-action col-right">
        <a rel="nofollow" data-target="cty" class="button-checkout-installment btn-add-installment btn-go-cart" href="https://trungdinh.com/pages/mua-hang-tra-gop/">
            <span class="title">Trả góp qua thẻ</span>
            <span>Visa, Mastercard, JCB</span>
        </a>
    </div>
		</div>
	</form>
</div>
</div>
<div class="product media">
	<div class="gallery-placeholder" data-gallery-role="gallery-placeholder">
		<div class="">
		 	<img class="gallery-placeholder__image" :src="item.featured_image_url"/>
		</div>
	</div>
	<div class="product-item__video">
	         
	</div>
	<div class="product-item__short-description">
	    <p style="text-align: justify;" v-html="item.description"></p></div>
	</div>
</div>
</div>
<div class="mainwrapper-right col-md-3" style="display:none;">    
	<div class="block-section">
        <div class="info-more info-note-more">
            <div class="info-more-head"> 
                <i class="fa fa-bag-info"></i>
                <strong>Bộ sản phẩm</strong>
            </div>
            <div class="info-content">
            	<p>Bộ sản phẩm gồm: Thân máy, Cáp USB-C to Lightning, Cây lấy sim, Sách HDSD.</p>
         	</div>
        </div>
        <hr class="line">
        <div class="info-more info-note-more">
              <div class="info-more-head"> 
            <i class="fa fa-shield-done"></i>
            <strong>Bảo hành</strong>
        </div>
    		<div class="info-content">
                <ul><li>Giá đã bao gồm 10% VAT. Bảo hành <strong>12 tháng</strong> tại trung tâm bảo hành chính hãng Apple Việt Nam. (<a href="https://trungdinh.com/tin-tuc/bao-hanh-gioi-han-mot-nam-cua-apple" target="_blank" rel="noopener">Xem chi tiết</a>)
				<p> </p>
				</li>
				<li><strong>Bảo hành <span style="color: #ff0000;">1 đổi 1 trong <span style="color: #ff0000;">33 ngày</span></span></strong> độc quyền tại Trung Đỉnh.</li>
				</ul>            
			</div>
        </div>
	</div>
<div class="block-detail-related mt-16">


</div>
</div>
</div>
<div class="kop-wraper">
<div class="kol-influencer-slider">
    <div class="row mt-16">
        <div class="influencer-slider-wrapper col-md-12">
            <div class="influencer-slider-content" style="display:none;">
                <div class="influencer-background float-left"></div>
                <div class="influencer-slider float-left p-8">
                        <div class="item">
                            <img alt="Á Hậu Kim Duyên" src="">
                            <p class="font-subtitle-1">Á Hậu Kim Duyên</p>
                            <div class="hover-star"></div>
                        </div>
             	</div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="bottom-wraper">
<div class="row">
<div class="details-info col-md-8">
	<div class="block-section">
		<div class="product attribute description">
			<div v-html="item.content"/>	    
		</div>
	</div>
</div>
<div class="details-relateds col-md-4" v-if="item.data">
	<div class="block-section">    
		<div class="additional-attributes-wrapper table-wrapper product-dtcontent">
        <div class="attributes-list">
        <div class="head-attribute-title"><i class="fa fa-setting"></i><span class="attribute-title">Thông số | Cấu hình</span></div>
        <hr class="line">
        <div class="data table additional-attributes" id="product-attribute-specs-table">
        <ul class="display">
	        <li v-for="val,attr in item.data"><p>{{attr}}</p><div><span>
	        <ul>
	        	<li v-for="v,a in val">{{a}}: {{v}}</li>
	        </ul>
	        </span></div></li>
        </ul>
        </div>
	</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</main>
</template>
<script>
import LayoutItem from '../layouts/LayoutItem.vue';
import VueMetamask from 'vue-metamask';
import axios from 'axios'
import config from '../config.js';
import { defineComponent, registerRuntimeCompiler, toRefs } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import "@/assets/trungdinh/css/item.css";
import StarRating from 'vue-star-rating';

export default {
    name: 'Item',
    components: {
    	VueMetamask,
    	Carousel,
	    Slide,
	    Pagination,
	    Navigation,
	    StarRating,
	},
    data() {
        return {
            item: "",
            quantity: 1,
            user:{},
            rate:1,
            eth_price:{},
            showImagePreview:true,
            showVideoPreview:false,
            
            metamaskWallet:{},
	        metamask_address: "",
	        metamask_type:"",
			
            api_baseurl:"",
            tokenId:0,
            explorer_url: config.explorerUrl,
            contract_address: config.contractAddress,
            file_type:"image",
        }
    },
    
    mounted() {
    	const plugin = document.createElement("script");
    	plugin.setAttribute("src","https://cdnjs.cloudflare.com/ajax/libs/fotorama/4.6.4/fotorama.min.js");
    	plugin.setAttribute("integrity","sha512-cWEytOR8S4v/Sd3G5P1Yb7NbYgF1YAUzlg1/XpDuouZVo3FEiMXbeWh4zewcYu/sXYQR5PgYLRbhf18X/0vpRg==");
    	plugin.setAttribute("crossorigin","anonymous");
    	plugin.setAttribute("referrerpolicy","no-referrer");
    	plugin.async = true;
    	document.head.appendChild(plugin);
    	
    	const linkTag = document.createElement("link");
    	linkTag.setAttribute("rel","stylesheet");
    	linkTag.setAttribute("href","https://cdnjs.cloudflare.com/ajax/libs/fotorama/4.6.4/fotorama.min.css");
    	linkTag.setAttribute("integrity","sha512-bjwk1c6AQQOi6kaFhKNrqoCNLHpq8PT+I42jY/il3r5Ho/Wd+QUT6Pf3WGZa/BwSdRSIjVGBsPtPPo95gt/SLg==");
    	linkTag.setAttribute("crossorigin","anonymous");
    	linkTag.setAttribute("referrerpolicy","no-referrer");
    	linkTag.async = true;
    	document.head.appendChild(linkTag);
    	
    	this.api_baseurl= axios.defaults.baseURL
        this.getItem() 
        this.rate = this.$store.state.rate
		this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
	  	console.log(this.metamaskWallet);
	    if(this.metamaskWallet){
	    	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
	    }
	    console.log(this.metamask_addresss);
    },
    methods: {
    	async bookAService() {
    		const user = JSON.parse(localStorage.getItem("user"));
    		const serviceFormData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
	    console.log(serviceFormData)
	    await axios
                .post("/api/objectregistrations/", serviceFormData)
                .then(response => {
                	console.log(response.data);
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	updated() {
    		$(function () {
			  $('.fotorama').fotorama();
			});
    	},
    	created() {
	    	this.$emit('update:layout', LayoutItem);
	    },
    	onComplete(data){
	  		console.log("ttttt....");
	    	console.log('data:', data);
	    	if(!data['metaMaskAddress']) {
	    		this.$store.commit('showToast', 'Please download Metamask to mint NFT. Link: https://metamask.io/download.html');
	    	}
	    	console.log({web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	
	    	this.$store.commit('setMetamaskWallet', {web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	this.metamask_address=data['metaMaskAddress']
	    	console.log(this.metamask_address)
	    	this.metamask_type=data['type']
	    	console.log(this.metamask_type);
	    	
	    	if ('MAINNET' !== this.metamask_type) {
	    		this.isRightNetwork=false;
	    		this.$store.state.isRightNetwork=false;
	    		this.$store.commit('showToast', 'Please choose Mainnet Network!');
	    	} else {
	    		if (137 !== data['netID']) {
	    			this.$store.commit('showToast', 'Please choose Polygon Mainnet Network!');
	    		} else {
	    			this.$store.commit('showToast', 'Please choose Polygon Mainnet Network!');
	    		}
	    		this.isRightNetwork=true;
	    		this.$store.state.isRightNetwork=true;
	    	}
	    	
	    	this.isRightNetwork=true;
	    	this.$store.state.isRightNetwork=true;
	    },
    	async transferNFT() {
			//const API_URL="https://eth-ropsten.alchemyapi.io/v2/mkmpYPZHBR0zola6xOzx4A8E5XCcrbcI"
			const API_URL=config.apiUrl
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			const MYWALLET_ADDRESS = this.metamaskWallet['metaMaskAddress']
			console.log(MYWALLET_ADDRESS);
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			let allow_buy_price = '0';
			let tokenId = '0';
			if (this.item.transaction) {
				allow_buy_price = this.item.transaction.allow_buy_price;
				tokenId = this.item.transaction.tokenId;
			}
			//const fValue = 1000000000000000000*this.item.price/this.$store.state.rate.rates['ETH'];
			//const WEI_PRICE = parseFloat(1000000000000000000*this.item.price/this.$store.state.rate.rates['ETH']).toFixed(0).toString();
			//const ETH_PRICE = web3.utils.toWei(WEI_PRICE, 'ether'); 
			//const ETH_PRICE = web3.utils.toHex(web3.utils.toWei(fValue, 'ether'));
			const ETH_PRICE = allow_buy_price;
			//const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			const contract = require("../../artifacts/contracts/VONMIVERSE.sol/VONMIVERSE.json")
			console.log(JSON.stringify(contract.abi))
			//const contractAddress = "0xd0F6413e284Ee26D5dDCd9F3A2e1b90A0E6215EA" is first 
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(MYWALLET_ADDRESS, "latest") //get latest nonce
			console.log(ETH_PRICE)
			//the transaction
			nftContract.methods.buy(tokenId).send({from:MYWALLET_ADDRESS,value:ETH_PRICE}).on('transactionHash', function(hash){
				console.log(hash);
				            console.log(
				              "The hash of your transaction is: ",
				              hash,
				              "\nCheck Alchemy's Mempool to view the status of your transaction!"
				            )
			})
			
			return 1
			
			const method_data = nftContract.methods.buy(tokenId).encodeABI()
				const tx = {
					    from: MYWALLET_ADDRESS,
			    		to: contractAddress,
					    nonce: nonce,
					    gas: 500000,
					    data: method_data,
					}
					
					console.log(tx)
					await web3.eth.sendTransaction(
			        tx,
			        function (err, hash) {
			          if (!err) {
				            this.message = "The hash of your transaction is: "+hash;
				            console.log(
				              "The hash of your transaction is: ",
				              hash,
				              "\nCheck Alchemy's Mempool to view the status of your transaction!"
				            )
				            const interval = setInterval(function() {
						        console.log("Attempting to get transaction receipt...");
						        web3.eth.getTransactionReceipt(hash, function(err, rec) {
						          if (rec) {
						            console.log(rec);
						            clearInterval(interval);
						            //nftContract.methods.buy(tokenId).send({from:MYWALLET_ADDRESS,value:ETH_PRICE});
						            
						            const item = JSON.parse(localStorage.getItem("item"))
						            const user = JSON.parse(localStorage.getItem("user"))
						            console.log(item)
						            console.log(item.uuid)
						            const formData = {
										    active:1,
										    id:item.id,
										    uuid:item.uuid,
										    user:user.id,
						            }
						            axios
						                .put("/api/items/"+item.uuid+"/", formData)
					                .then(response => {
					                	console.log(response.data);
					                	localStorage.setItem("item", JSON.stringify(response.data));
					                    this.message = "Update the item successfully!";
					                })
					                .catch(error => {
					                    if (error.response) {
					                        for (const property in error.response.data) {
					                            this.$store.commit('showToast', error.response.data[property]);
					                        }
					                    } else {
					                        this.$store.commit('showToast', 'Something went wrong. Please try again');
					                        console.log(JSON.stringify(error))
					                    }
					                }) 
						          }
						        });
						      }, 1000);
				            
				            
				            
				          } else {
				            console.log(
				              "Something went wrong when submitting your transaction:",
				              err
				            )
				          }
				        }
				      )
			
		},
    	showVideo(event) {
    		let video = document.getElementById('video-preview');
			let reader = new FileReader();
			console.log( this.item.asset_file_url )
			video.src=axios.defaults.baseURL+this.item.asset_file_url
			this.showImagePreview = false;
			this.showVideoPreview = true;
			/*reader.readAsDataURL( new File([await (await fetch(event.target.dataset.fileurl)).blob()], event.target.dataset.fileurl) );
			reader.addEventListener('load', function(){
				this.showImagePreview = false;
				this.showVideoPreview = true;
				video.src = reader.result;
			}.bind(this), false);*/
    	},
        async getItem() {
            const category_slug = this.$route.params.category_slug
            const slug = this.$route.params.slug
            await axios
                .get(`/api/items/${slug}/?image_sizes=600x600&user_image_sizes=65x65`)
                .then(response => {
                	console.log(response.data)
                    this.item = response.data
                    document.title = this.item.title
                    localStorage.setItem("item", JSON.stringify(response.data));
                    
                })
                .catch(error => {
                    console.log(error)
                })
        },
        addToCart() {
        	console.log("Add to cart");
        	
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const itemData = {
                product: this.item,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', itemData)
        }
    }
}
</script>