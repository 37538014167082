<template>
  <component :is="layout"> 
  	<router-view /> 
  </component>
</template>

<script setup>

import { defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router';
let layout = computed( () => { 
        const { meta } = useRoute();
        try{
        	console.log(meta.layout.name);
        	return defineAsyncComponent( () => import(`./layouts/${meta.layout.name ?? 'LayoutDefault'}.vue`) )
        } catch (e) {
        	return defineAsyncComponent( () => import(`./layouts/LayoutDefault.vue`) )
        }
    }
)
</script>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      fullPage: false,
      settings: {
          arrows: true,
          dots: true,
          prevArrow: "",
          nextArrow: "",
          
      },
      biz_resp_data: "",
      metamask_address: "",
      msg: "This is demo net work",
      trendingArtworks:[],
      liveAuctions:[],
      featuredAssets:[],
      topAuthors:[],
      hotCollections:[],
      topSellers:[],
      rate_amount:1,
      brands:[],
      homeSliders:[],
      featuredArtists:[],
      api_baseurl:"",
      categories:[],
      popular_artists:[],
      vn_popular_artists:[],
      topPicks:[],
      classes:[],
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  beforeCreate() {
  	console.log("beforeCreate");
  },
  created() {
    console.log("created");
  },
  methods: {
    doAjax() {
        this.isLoading = true;
        // simulate AJAX
        setTimeout(() => {
        	this.isLoading = false
    	}, 5000)
    },
    onCancel() {
    	console.log('User cancelled the loader.')
    },
    async processBizname() {
      await axios
        .get('/api/process-bizname/', {
          params: {
            bizname: this.bizname
          }
        })
        .then(response => {
          this.biz_resp_data = response.data
          console.log(this.biz_resp_data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getMiscData() {
      await axios
        .get('/api/misc-data/')
        .then(response => {
          this.$store.commit('setMiscData', response.data)
          this.$store.state.cart = response.data.cart
          this.$store.state.brands = response.data.brands
          this.$store.state.storages = response.data.storages
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
	async getServices() {
		
	  		await axios
	                .get("/api/posts/?group=trending&image_sizes=600x600&user_image_sizes=65x65&page_size=10&post_type=3")
	                .then(response => {
						this.$store.commit('setServices', response.data.results);
						this.services=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	},
	async getItemList() {
	  		await axios
	                .get("/api/items/?group=trending&image_sizes=600x600&user_image_sizes=65x65")
	                .then(response => {
						this.$store.commit('setTrendingArtworks', response.data.results);
						this.trendingArtworks=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  async getAuctionList() {
	  		await axios
	                .get("/api/items/?group=live_auction&image_sizes=550x670")
	                .then(response => {
						this.$store.commit('setLiveAuctions', response.data.results);
						this.liveAuctions=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getFeaturedAssets() {
	  		await axios
	                .get("/api/items/?group=featured&image_sizes=600x600&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setFeaturedAssets', response.data.results);
						this.featuredAssets=response.data.results
						console.log(this.featuredAssets.length)
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	
	  	async getTopPicks() {
	  		await axios
	                .get("/api/items/?group=top_picks&image_sizes=600x600&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.topPicks=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	
	  	async getFeaturedArtists() {
	  		await axios
	                .get("/api/users/?group=featured_artists&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.featuredArtists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getPopularArtists() {
	  		await axios
	                .get("/api/users/?group=popular_artists&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.popular_artists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getVNPopularArtists() {
	  		await axios
	                .get("/api/users/?group=popular_artists&location=vn&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.vn_popular_artists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getTopAuthors() {
	  		await axios
	                .get("/api/users/?group=top_authors&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						//console.log(response.data)
						this.$store.commit('setTopAuthors', response.data);
						this.topAuthors=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getTopSellers() {
	  		await axios
	                .get("/api/users/?group=top_sellers&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTopSellers', response.data.results);
						this.topSellers=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getHotCollections() {
	  		await axios
	                .get("/api/items/?group=hotcollections&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setHotCollections', response.data.results);
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getItemCategories() {
	  		await axios
	                .get("/api/itemcategories/?page_size=16&page_size=205x205")
	                .then(response => {
						this.$store.commit('setItemCategories', response.data.results);
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getClasses() {
	  		console.log(this.$refs.loadingContainer);
	  		/*
	  		let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.loadingContainer,
                    loader:'dots',
                    isFullPage: false,
                    canCancel: true,
                    onCancel: this.onCancel,
                });*/
	  		await axios
	                .get("/api/classes/?page_size=6&page_size=205x205")
	                .then(response => {
	                	console.log(response.data.results);
						this.classes = response.data.results;
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
 },
  mounted() {
    document.title = 'Home'
    this.api_baseurl= axios.defaults.baseURL
  },
  computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
  updated() {
  }
}

</script>