<template>
<div class="page messages">
	<div data-placeholder="messages"></div>
<div data-bind="scope: 'messages'">
    <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
    <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
        </div>
    </div>
    <!-- /ko -->

    <!-- ko if: messages().messages && messages().messages.length > 0 -->
    <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
        </div>
    </div>
    <!-- /ko -->
</div>
</div>
<div class="columns">
<div class="content-wrapper">
<div class="home-top-promotion container">
    <div class="row mt-16">
        <div class="homepage-trend">
<div id="group-trend">
    <div class="trend-title">
        <div class="trend-title-image"></div>
    </div>
    <div class="trend-body">
        <div class="inner trend-owl">
                            <div class="trend-item trend-item-0">
                <div class="trend-box">
                    <a target="_blank" href="/samsung-galaxy-z-series-2022.html" title="Galaxy Z Mới">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #1                                </div>
                                <div class="trend-info">
                                    <h3>Galaxy Z Mới</h3>
                                </div>
                            </div>
                            <p>Đặt Gạch. Ưu đãi khủng</p> 
                        </div>
                    </a>
                </div>
            </div>
                                            <div class="trend-item trend-item-1">
                <div class="trend-box">
                    <a target="_blank" href="/macbook-pro-13-inch-2022-m2-256gb.html" title="MacBook Pro M2">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #2                                </div>
                                <div class="trend-info">
                                    <h3>MacBook Pro M2</h3>
                                </div>
                            </div>
                            <p>Thiết kế đỉnh. Chip cực mạnh</p> 
                        </div>
                    </a>
                </div>
            </div>
                                            <div class="trend-item trend-item-2">
                <div class="trend-box">
                    <a target="_blank" href="/iphone-13-pro-max-128gb.html" title="iPhone 13 Pro Max">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #3                                </div>
                                <div class="trend-info">
                                    <h3>iPhone 13 Pro Max</h3>
                                </div>
                            </div>
                            <p>Sang trọng. Giá hấp dẫn</p> 
                        </div>
                    </a>
                </div>
            </div>
                                            <div class="trend-item trend-item-3">
                <div class="trend-box">
                    <a target="_blank" href="/oppo-reno8-z-256gb.html" title="Oppo Reno8 Z 5G">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #4                                </div>
                                <div class="trend-info">
                                    <h3>Oppo Reno8 Z 5G</h3>
                                </div>
                            </div>
                            <p>Đặt trước ngay. Giá hấp dẫn</p> 
                        </div>
                    </a>
                </div>
            </div>
                                            <div class="trend-item trend-item-4">
                <div class="trend-box">
                    <a target="_blank" href="/airpods-pro-2021.html" title="AirPods Pro 2021">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #5                                </div>
                                <div class="trend-info">
                                    <h3>AirPods Pro 2021</h3>
                                </div>
                            </div>
                            <p>Chống ồn. Siêu êm tai</p> 
                        </div>
                    </a>
                </div>
            </div>
                                            <div class="trend-item trend-item-5">
                <div class="trend-box">
                    <a target="_blank" href="/laptop-acer" title="Laptop Acer">
                        <div class="item-inner">
                            <div class="trend-top">
                                <div class="trend-pos">
                                    #6                                
                                </div>
                                <div class="trend-info">
                                    <h3>Laptop Acer</h3>
                                </div>
                            </div>
                            <p>Cấu hình mạnh. Giá tốt</p> 
                        </div>
                    </a>
                </div>
            </div>
    	</div>
    </div>
</div>
</div>
	<div class="promotion-slider col-md-9">
    	<div v-if="this.miscData">
    		<carousel class="promotion-main-slider">
    			<slide v-for="object in this.miscData.sliders.home_sliders" :key="object">
                 	<div class="item">
                        <a :href="object.url">
                            <img :alt="object.title" :src="object.image_url"/>
                        </a>
                    </div>
             	</slide>
             	<template #addons>
      				<navigation />
      				<pagination />
    			</template>
             </carousel>               
        </div>
  	</div>
        <div class="promotion-banner col-md-3" v-if="this.miscData">
            <div class="item hot-deal-accessories text-center">
                <a href="#" class="font-subtitle-2">Khuyến mãi phụ kiện hot!</a>
            </div>
                 <div class="item text-center" v-for="object in this.miscData.home_banners" :key="object">
                    <a :href="object.url">
                        <img :alt="object.title" :src="object.image_url">
                    </a>
                </div>
   		</div>
    </div>
</div>
<div class="homepage-menu-cat container">
    <div class="row mt-16">
        <div class="menu-cat-wrapper col-md-12">
            <div class="menu-cat-content homepage-content-wrapper p-16">
                <p>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/iPhone_13_series.png" alt="" width="79" height="80">iPhone 13<br>Series VN/A&nbsp;</a> 
                <a href="/samsung-galaxy-s22" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/icon-s-22-didongviet_1.png" alt="" width="80" height="80">Samsung Galaxy<br>S22 Series&nbsp;</a>
                <a class="cat-bluetooth-speaker" href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/laptop.png" alt="" width="80" height="80">Laptop giảm <br>đến 4,5&nbsp;triệu </a>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/iPad-Pro-2020.png" alt="">iPad Pro 2021<br>giảm đến 6,1 triệu</a> 
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/may-cu-gia-re.png" alt="">iPhone&nbsp;cũ<br>giá rẻ</a>
                <a href="#" target="_blank" rel="noopener"> <img src="../assets/trungdinh/images/cat/phu-kien-apple-80x80-didongviet.png" alt="">Phụ kiện<br>Apple giá từ 490K</a>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/menu.png" alt=""> Loa Nghe Nhạc giảm đến 58%</a>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/cap-sac.png" alt="">Cáp củ sạc<br>giảm đến 60%</a>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/enginer.png" alt="">Pin dự phòng <br>chỉ từ 150K</a> 
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/case.png" alt="">Ốp lưng UAG <br>mua 1 được 3</a>
                <a href="#" target="_blank" rel="noopener"><img src="../assets/trungdinh/images/cat/airpods-max.png" alt=""> Tai nghe<br>giảm đến 63%</a>
                </p>
			</div>
        </div>
    </div>
</div>   
<div class="home-tradein-bn">
	<div class="image">
    	<a href="/samsung-galaxy-z-series-2022.html">
        	<img  class="image wow fadeInUp" alt="" src="../assets/trungdinh/images/banners/1280x109_1_1.png">
      	</a>
	</div>
</div>
<div class="homepage-content-top"> 
	<div class="group-featured-style-2 slider-custom slider-flash-sale block-slider-1 container mt-16">
	    <div class="homepage-content-wrapper">
        	<div class="  ">
            	<a href="/khuyen-mai/uu-dai-nguyen-an-ninh"><img src="../assets/trungdinh/images/1280x109_11.jpg" alt="Tết Siêu Sale - Săn Deal Giờ Vàng"/></a>                    
            </div>
            <div class="slider-content">
            	<div v-if="this.items">
            		<carousel  class="products list items product-slider product-sliders product-items slides" :items-to-show="5">
    				<slide v-for="object in this.items" :key="object">
            		<div class="item product product-item" style="background: #fff;border-radius: 5px;-webkit-border-radius: 5px;margin: 0 0.5%;">
        				<div class="wrap-item">
           					<div class="product-label" style="display:none;"><span class="percent-deal"></span></div>            
           					<div class="product-item-image">
                				<a :title="object.title" class="product-item-link" :href="'/items/'+object.slug+'/'">
                    				<div>
                        				<img :alt="object.title" :src="object.featured_image_url"/>
                    				</div>
                				</a>
            				</div>
            				<div class="product-item-info">
            					<div class="label-sticker-title"><img src="../assets/trungdinh/images/deal-soc.png"></div>
                            	<a :title="object.title" class="product-item-link product-name font-subtitle-1" :href="'/items/'+object.slug+'/'">
                    				<h3>{{object.title}}</h3>
                				</a>
                				<div class="box-price-product">
                    				<div class="price-box price-final_price" data-role="priceBox" data-product-id="19060" data-price-box="product-id-19060">
                    				<span class="normal-price">
										<span class="price-container price-final_price tax weee">
            								<span class="price-label">Mua ngay</span>
        									<span id="product-price-19060" :data-price-amount="object.price" data-price-type="finalPrice" class="price-wrapper">
        										<span class="price">{{object.price}} {{object.currency_symbol}}</span><span class="discount"></span>
        										<span class="market-price">{{object.old_price}} {{object.currency_symbol}}</span>        
        									</span>
        								</span>
   									</span>
								</div>                                    
							</div>
                			<div class="box-product-bottom" style="display:none;">
                            	<div class="product-icons product-1-icon">
                            		<span>
                            			<img src="../assets/trungdinh/images/artboard_18_6.jpg"/>
                            		</span>
                            	</div>             
                            </div>
                    	</div>
        			</div>
    			</div>
    			</slide>
    			<template #addons>
      				<navigation />
      				<!--pagination /-->
    			</template>
    			</carousel>
   			</div>
 		</div>
	</div>
</div> 
<div class=" slider-custom block-slider-2 container mt-16">
    <div class="homepage-content-wrapper">
        <div class="slider-header">
            <h2 class="slider-title pb-16 bold">Dịch vụ sửa chữa điện thoại, laptop chuyên nghiệp</h2>
            <div class="sub-links" style="display:none;">
				<a href="#" target="_blank"></a>                                
			</div>
      	</div>
        <div class="slider-content">
        <div v-if="this.services">
        <carousel class="products list items product-slider product-slider product-sliders product-items slides row" :settings="settings" :breakpoints="breakpoints">
    	<slide v-for="service in this.services" :key="service">
      <div class="item product product-item product-item">
        	<div class="wrap-item">
                <div class="product-label"></div>            
	            <div class="product-item-image">
	                <a :title="service.title" class="product-item-link" :href="'/posts/'+service.slug+'/'">
	                    <div>
	                        <img :alt="service.title" :src="service.featured_image"/>
	                    </div>
	                </a>
	            </div>
            <div class="product-item-info">
                <div  class="label-sticker-title" style="display:none;">
                    <img src="../assets/trungdinh/images/sale-soc.png"/>
                </div>                
                <a :title="service.title" class="product-item-link product-name font-subtitle-1" :href="'/posts/'+service.slug+'/'">
                    <h3>{{service.title}}</h3>
                </a> 
                <div class="box-price-product">
                    <div class="price-box price-final_price" data-role="priceBox" data-product-id="17749" data-price-box="product-id-17749">
                        <span class="normal-price">
                            <span class="price-container price-final_price tax weee">
                                <span class="price-label">Mua ngay</span>
                                <span id="product-price-17749" :data-price-amount="service.price" data-price-type="finalPrice" class="price-wrapper">
                                    <span class="price">{{service.price}}</span>        
                                </span>
                            </span>
                        </span>

                    </div>                    
                    <div class="col-installment"  style="display:none;">
                        <span class="price-label">Hoặc trả trước</span><span class="price">1</span>
                    </div>                
                </div>
                <div class="box-product-bottom" style="display:none;">
                    <span class="label-special-gift gift-lll">Tặng thêm <strong>1đ</strong> khi Thu cũ đổi mới. Giảm thêm <strong>600.000đ</strong> khi mở thẻ TPBank</span>                    
                </div>
            </div>
        </div>
    </div>
    </slide>

    <template #addons>
      <navigation />
      <!--pagination /-->
    </template>
  </carousel>
</div>
</div>
</div>
</div>
</div> 
<div class="home-bottom-banner container" style="display:none;">
    <div class="group-slide">
    	<div class="item text-center">
                    <a href="/khuyen-mai/gio-vang-gia-soc">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_7__2.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dchannel/08-08-ngay-doi-sale-ngay-doi-dien-thoai-laptop-tablet-da-giam-sau-nay-lai-giam-them-cuc-manh-tu-08h-11h-giam-them-den-500-ngan-tu-11h-21h-giam-them-200-ngan-den-di-don/">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_54.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dong-ho-thong-minh-huawei.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_1__15.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/khuyen-mai/ngay-vang-phu-kien">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_6__9.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dchannel/duy-nhat-08-08-ngay-doi-sale-gap-boi-phu-kien/">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_5__2.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/iphone-cu.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_2__7.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dong-ho-thong-minh-amazfit.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_3__7.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/phu-kien-sennheiser.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_14__5.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/laptop-huawei.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_12__2.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/khuyen-mai/phu-kien">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_2__13.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/khuyen-mai/sale-thang-8">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_127.png">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/macbook-air.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_50.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/vong-deo-tay-thong-minh-huawei-band-7.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150-80_1__9.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/vivo">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150-80_25.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/apple-watch-series-7.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_129.png">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/ipad-air-5.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_120.png">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/laptop-huawei-matebook-d14.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_13__2.jpg">
                    </a>
                </div>
    </div>
</div>
<div class="homepage-content-middle"> 
	<div class=" slider-custom block-slider-3 container mt-16">
            <div class="homepage-content-wrapper">
            	<div class="slider-header"> 
                	<h2 class="slider-title pb-16 bold">Điện thoại nổi bật nhất</h2>
		<div class="sub-links">
			<a href="/galaxy-z-fold3-z-flip3-5g" target="_blank">Galaxy Z Fold3 & Z Flip3</a>
			<a href="/samsung-galaxy-s22" target="_blank">Galaxy S22 series</a>
			<a href="/samsung-galaxy-a" target="_blank">Galaxy A series</a>
			<a href="/oppo" target="_blank">Oppo</a>
			<a href="/xiaomi" target="_blank">Xiaomi</a>
			<a href="/vivo" target="_blank">Vivo</a>
		</div>
	</div>
    <div class="slider-content">
    	<div v-if="this.items">
        <carousel class="products list items product-slider product-sliders product-items slides row" :settings="settings" :breakpoints="breakpoints">
        <slide v-for="object in this.items" :key="object">
        <div class="item product product-item product-item">
        <div class="wrap-item">
             <div class="product-label" style="display:none;"><span class="percent-deal"></span></div>            
             <div class="product-item-image">
                <a :title="object.title" class="product-item-link" :href="'/items/'+object.slug+'/'">
                    <div>
                        <img :alt="object.title" :src="object.featured_image_url"/>
                    </div>
                </a>
            </div>
            <div class="product-item-info">
            	<div class="label-sticker-title" style="display:none">
            		<img src="../assets/trungdinh/images/gia-kho.png"/>
            	</div>                
            	<a :title="object.title" class="product-item-link product-name font-subtitle-1" :href="'/items/'+object.slug+'/'">
                    <h3>{{object.title}}</h3>
                </a> 
                <div class="box-price-product">
                    <div class="price-box price-final_price" data-role="priceBox" :data-product-id="object.id" :data-price-box="'product-id-'+object.id">
                    	<span class="normal-price">
							<span class="price-container price-final_price tax weee">
            					<span class="price-label">Mua ngay</span>
        						<span :id="'product-price-'+object.id" :data-price-amount="object.price" data-price-type="finalPrice" class="price-wrapper ">
        							<span class="price">{{object.price}} {{object.currency_symbol}}</span>
        							<span class="discount"></span>
        							<span class="market-price" v-if="object.old_price>object.price">{{object.old_price}} {{object.currency_symbol}}</span>        
        						</span>
        					</span>
   						</span>
					</div>                                    
				</div>
				<div class="box-product-bottom" style="display:none;">
    				<span class="label-special-gift gift-lll"><strong></strong><strong></strong></span>
   				</div>
    		</div>
		</div>
	</div>
</slide>
<template #addons>
    <navigation />
	<!--pagination /-->
</template>
</carousel>      
</div>
</div>
</div>
</div>
<div class="custom-menu-cat-wrapper">
    <div class="container">
    	<div class=" homepage-content-wrapper">
        	<div class="trademark-head">
        		<h3>Chuyên trang thương hiệu</h3>
        	</div> 
        	<div class="trademark-body">
        		<div v-if="this.miscData">
        			<carousel class="image-slider slider-trademark" :settings="settings" :breakpoints="breakpoints">
    					<slide v-for="object in this.miscData.brands" :key="object">
                 			<div class="item">
                    			<a :href="object.url">
                        			<img class="img-reponsive" :alt="object.name" :src="object.image_url">
                    			</a>
                   			</div>
             			</slide>
             			<template #addons>
      						<navigation />
      						<!--pagination /-->
    					</template>
             		</carousel>       
        		</div>
        	</div>
    	</div>
    </div>
</div>
</div> 

<div class="home-bottom-banner container" style="display:none;">
	<div class="group-slide">
     	<div class="item text-center">
         	<a href="/samsung-galaxy-a">
           		<img alt="" src="../assets/trungdinh/images/banners/dattruocaseries-768x150_1.jpg">
        	</a>
		</div>
       	<div class="item text-center">
                    <a href="/samsung-galaxy-s22.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150--1.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dong-ho-tre-em.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150-80_6__1.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/dong-ho-thong-minh-huawei.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_15__1.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/khuyen-mai/xa-kho-thang-8">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150.jpeg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/loa-bluetooth-havit-sk854bt.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_14__4.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/vong-deo-tay-thong-minh-xiaomi-mi-band-7.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_6__8.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/loa-bluetooth-jbl-flip-6.html">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_2__12.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/galaxy-s21-fe.html">
                        <img alt="" src="../assets/trungdinh/images/banners/_u-_i-ch_t-l_-s21-fe_6.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/samsung-galaxy-z.html">
                        <img alt="" src="../assets/trungdinh/images/banners/galaxy-z-fold3---z-flip3.jpg-768x150.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/vivo">
                        <img alt="" src="../assets/trungdinh/images/banners/768x150_-80.jpg">
                    </a>
                </div>
                            <div class="item text-center">
                    <a href="/the-tin-dung-dong-thuong-hieu-tpbank-evo">
                        <img alt="" src="../assets/trungdinh/images/banners/bank-evo-11-11.jpg">
                    </a>
                </div>
                    
        
    </div>
</div>
<div class="homepage-content-bottom">
	<div class=" slider-custom block-slider-4 container mt-16" v-for="itemCategory in this.itemCategories">
		<div class="homepage-content-wrapper">
        	<div class="slider-header"> 
            	<h2 class="slider-title pb-16 bold">
                	{{itemCategory.title}}                           
              	</h2>
                <div class="sub-links" style="display:none;">
					<a href="#" target="_blank"></a>                                
				</div>
        	</div>
        	<div class="slider-content">
      			<div class="products list items product-slider product-slider product-sliders product-items slides row" v-if="itemCategory.items">
            	<div class="item product product-item" v-for="object in itemCategory.items">
        		<div class="wrap-item">
           			<div class="product-label" style="display:none;">
           				<span class="percent-deal"></span>
           			</div>            
           			<div class="product-item-image">
                		<a title="" class="product-item-link" :href="'/items/'+object.slug + '/'">
                    		<div>
                        		<img :alt="object.title" :src="object.featured_image_url"/>
                    		</div>
                		</a>
            		</div>
            		<div class="product-item-info">
            			<div style="left: 0px !important;right: auto !important;top:0px;display:none;" class="label-sticker-title">
            				<img src="../assets/trungdinh/images/hot-sale.png"/>
            			</div>                
            			<a title="" class="product-item-link product-name font-subtitle-1" :href="'/items/'+object.slug + '/'">
                    		<h3>{{object.title}}</h3>
                		</a>
                		<div class="box-price-product">
                    		<div class="price-box price-final_price" data-role="priceBox" data-product-id="15129" data-price-box="product-id-15129">
                    			<span class="normal-price">
									<span class="price-container price-final_price tax weee">
            							<span class="price-label">Mua ngay</span>
        								<span :id="'product-price-'+object.id" :data-price-amount="object.price" data-price-type="finalPrice" class="price-wrapper">
        								<span class="price">{{object.price}} {{object.currency_symbol}}</span>
        								<span class="discount" v-if="object.old_price > object.price"></span><span class="market-price">{{object.old_price}} {{object.currency_symbol}}</span></span>
        							</span>
   								</span>

							</div>                    
							<div class="col-installment" style="display:none;">
								<span class="price-label"></span>
								<span class="price"></span>
							</div>                
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div> 


<div class=" slider-custom block-slider-8 container mt-16">
	<div class="homepage-content-wrapper">
    	<div class="slider-header"> 
        	<h2 class="slider-title pb-16 bold">Phụ kiện xịn - Giao tận nơi</h2>
      	</div>
        <div class="accessories-content">
        	<div class="menu-cat-content mt-16 pb-16">
                                <a class="cat-iphone" href="/phu-kien-apple-chinh-hang" target="_blank" rel="noopener">Phụ kiện<br>Apple</a>
<a class="cat-samsung" href="/phu-kien-samsung" target="_blank" rel="noopener">Phụ kiện<br>Samsung</a>
<a class="cat-power-bank" href="/pin-sac-du-phong" target="_blank" rel="noopener">Pin sạc<br>dự phòng</a>
<a class="cat-cable" href="/cap-sac" target="_blank" rel="noopener">Dây<br>cáp sạc</a>
<a class="cat-adapter" href="/cu-sac-adapter" target="_blank" rel="noopener">Adapter<br>củ sạc</a>
<a class="cat-ear-phone" href="/tai-nghe" target="_blank" rel="noopener">Tai nghe<br>Bluetooth</a>
<a class="cat-bluetooth-speaker" href="/loa-nghe-nhac" target="_blank" rel="noopener">Loa<br>Bluetooth</a>
<a class="cat-phone-case" href="/bao-da-op-lung" target="_blank" rel="noopener">Ốp lưng<br>Bao da</a>
<a class="cat-phone-tempered-glass" href="/mieng-dan-man-hinh" target="_blank" rel="noopener">Kính<br>cường lực</a>
<a class="cat-robot-technology" href="/phu-kien-tien-ich" target="_blank" rel="noopener">Đồ chơi<br>công nghệ</a>
<a class="cat-apple" href="/thuong-hieu" target="_blank" rel="noopener">Thương hiệu<br>nổi bật</a>                            </div>
                                                <div class="accessories-wrapper grid-container mt-16">
                                    <div class="accessories-item grid-item m-0">
        <div class="accessories-item-image">
            <a title=" Tai Nghe True Wireless HAVIT TW967 Chính Hãng" class="accessories-item-link" href="/tai-nghe-true-wireless-havit-tw967.html">
                <img alt="Tai Nghe True Wireless HAVIT TW967 Chính Hãng" src="../assets/trungdinh/images/cat/tai-nghe-true-wireless-havit-tw967-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Tai Nghe True Wireless HAVIT TW967 Chính Hãng" class="product-item-link font-subtitle-2" href="/tai-nghe-true-wireless-havit-tw967.html">
            <h3>Tai Nghe True Wireless HAVIT TW967 Chính Hãng</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="19060" data-price-box="product-id-19060"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-19060"                data-price-amount="290000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">290.000 ₫</span><span class="discount">Giảm 35%</span><span class="market-price">450.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Tai nghe không dây JBL Tune 230NC Chính Hãng" class="accessories-item-link" href="/tai-nghe-khong-day-jbl-tune-230-nc.html">
                <img alt="Tai nghe không dây JBL Tune 230NC Chính Hãng" src="../assets/trungdinh/images/cat/tai-nghe-khong-day-jbl-tune-230nc-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Tai nghe không dây JBL Tune 230NC Chính Hãng" class="product-item-link font-subtitle-2" href="/tai-nghe-khong-day-jbl-tune-230-nc.html">
            <h3>Tai nghe không dây JBL Tune 230NC Chính Hãng</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="19058" data-price-box="product-id-19058"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-19058"                data-price-amount="2290000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">2.290.000 ₫</span><span class="discount">Giảm 8%</span><span class="market-price">2.490.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Tai nghe không dây Samsung Galaxy Buds 2" class="accessories-item-link" href="/tai-nghe-bluetooth-samsung-galaxy-bud-2.html">
                <img alt="Tai nghe không dây Samsung Galaxy Buds 2" src="../assets/trungdinh/images/cat/tai-nghe-khong-day-samsung-galaxy-buds-2-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Tai nghe không dây Samsung Galaxy Buds 2" class="product-item-link font-subtitle-2" href="/tai-nghe-bluetooth-samsung-galaxy-bud-2.html">
            <h3>Galaxy Buds 2</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="12283" data-price-box="product-id-12283"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-12283"                data-price-amount="2790000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">2.790.000 ₫</span><span class="discount">Giảm 6%</span><span class="market-price">2.990.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Loa Bluetooth JBL Flip 6" class="accessories-item-link" href="/loa-bluetooth-jbl-flip-6.html">
                <img alt="Loa Bluetooth JBL Flip 6" src="../assets/trungdinh/images/cat/loa-bluetooth-jbl-flip-6-1-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Loa Bluetooth JBL Flip 6" class="product-item-link font-subtitle-2" href="/loa-bluetooth-jbl-flip-6.html">
            <h3>Loa Bluetooth JBL Flip 6</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="19532" data-price-box="product-id-19532"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-19532"                data-price-amount="2990000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2">2.990.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Loa Bluetooth Harman Kardon Onyx Studio 7" class="accessories-item-link" href="/loa-harman-kardon-onyx-studio-7.html">
                <img alt="Loa Bluetooth Harman Kardon Onyx Studio 7" src="../assets/trungdinh/images/cat/loa-bluetooth-harman-kardon-onyx-studio-7.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Loa Bluetooth Harman Kardon Onyx Studio 7" class="product-item-link font-subtitle-2" href="/loa-harman-kardon-onyx-studio-7.html">
            <h3>Loa Bluetooth Harman Kardon Onyx Studio 7</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="13320" data-price-box="product-id-13320"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-13320"                data-price-amount="5690000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">5.690.000 ₫</span><span class="discount">Giảm 5%</span><span class="market-price">5.990.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item m-0">
        <div class="accessories-item-image">
            <a title=" Củ sạc nhanh Samsung 25W " class="accessories-item-link" href="/cu-sac-nhanh-samsung-25w.html">
                <img alt="Củ sạc nhanh Samsung 25W " src="../assets/trungdinh/images/cat/adapter-cu-sac-nhanh-samsung-25w-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Củ sạc nhanh Samsung 25W " class="product-item-link font-subtitle-2" href="/cu-sac-nhanh-samsung-25w.html">
            <h3>Củ sạc nhanh Samsung 25W </h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="11846" data-price-box="product-id-11846"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-11846"                data-price-amount="350000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">350.000 ₫</span><span class="discount">Giảm 28%</span><span class="market-price">490.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Cáp Innostyle Jazzy USB-C To Lightning 18W MFI" class="accessories-item-link" href="/cap-innostyle-jazzy-c-to-lightning-18w-mfi">
                <img alt="Cáp Innostyle Jazzy USB-C To Lightning 18W MFI" src="../assets/trungdinh/images/cat/day-ca-sac-innostyle-usb-c-to-lighning-den-didongviet_1_1_1.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Cáp Innostyle Jazzy USB-C To Lightning 18W MFI" class="product-item-link font-subtitle-2" href="/cap-innostyle-jazzy-c-to-lightning-18w-mfi">
            <h3>Cáp Innostyle Jazzy USB-C To Lightning 18W MFI</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="6918" data-price-box="product-id-6918"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-6918"                data-price-amount="350000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">350.000 ₫</span><span class="discount">Giảm 12%</span><span class="market-price">400.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Pin sạc dự phòng Energizer 20.000mAh /3.7V Li-Polymer UE20010" class="accessories-item-link" href="/pin-sac-du-phong-energizer-20-000mah-3-7v-li-polymer-ue20010.html">
                <img alt="Pin sạc dự phòng Energizer 20.000mAh /3.7V Li-Polymer UE20010" src="../assets/trungdinh/images/cat/pin-sac-du-phong-energizer-20000mah-3-7v-li-polymer-ue-20010-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Pin sạc dự phòng Energizer 20.000mAh /3.7V Li-Polymer UE20010" class="product-item-link font-subtitle-2" href="/pin-sac-du-phong-energizer-20-000mah-3-7v-li-polymer-ue20010.html">
            <h3>Pin sạc dự phòng Energizer 20.000mAh /3.7V Li-Polymer UE20010</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="18770" data-price-box="product-id-18770"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-18770"                data-price-amount="450000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">450.000 ₫</span><span class="discount">Giảm 47%</span><span class="market-price">850.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Bộ combo cáp sạc Innostyle 20W Type-C to Lightning" class="accessories-item-link" href="/bo-combo-cap-sac-innostyle-20w-type-c-to-lightning.html">
                <img alt="Bộ combo cáp sạc Innostyle 20W Type-C to Lightning" src="../assets/trungdinh/images/cat/bo-combo-cap-sac-innostyle-20w-type-c-to-lightning-didongviet.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Bộ combo cáp sạc Innostyle 20W Type-C to Lightning" class="product-item-link font-subtitle-2" href="/bo-combo-cap-sac-innostyle-20w-type-c-to-lightning.html">
            <h3>Bộ combo cáp sạc Innostyle 20W Type-C to Lightning</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="15376" data-price-box="product-id-15376"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-15376"                data-price-amount="650000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2 spec-price">650.000 ₫</span><span class="discount">Giảm 31%</span><span class="market-price">950.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
            <div class="accessories-item grid-item">
        <div class="accessories-item-image">
            <a title=" Ốp lưng iPhone 13 Pro Max Gear4 - Crystal Palace Snap" class="accessories-item-link" href="/op-lung-iphone-13-pro-max-gear4-crystal-palace-snap.html">
                <img alt="Ốp lưng iPhone 13 Pro Max Gear4 - Crystal Palace Snap" src="../assets/trungdinh/images/cat/op-lung-iphone-13-pro-max-crystal-palace-snap-didongviet-2.jpg"/>
            </a>
        </div>
        <div class="accessories-item-info">
            <a title=" Ốp lưng iPhone 13 Pro Max Gear4 - Crystal Palace Snap" class="product-item-link font-subtitle-2" href="/op-lung-iphone-13-pro-max-gear4-crystal-palace-snap.html">
            <h3>Ốp lưng iPhone 13 Pro Max Gear4 - Crystal Palace Snap</h3>
            </a>
            <div class="box-product-bottom">
                <div class="box-price-product">
                                        <div class="price-box price-final_price" data-role="priceBox" data-product-id="14315" data-price-box="product-id-14315"><span class="normal-price">
    
<span class="price-container price-final_price tax weee"
        >
            <span class="price-label">Mua ngay</span>
        <span  id="product-price-14315"                data-price-amount="1200000"
        data-price-type="finalPrice"
        class="price-wrapper ">
           
        <span class="price font-subtitle-2">1.200.000 ₫</span>        </span>
        </span>
   </span>

</div>                </div>
            </div>
        </div>
    </div>
                            </div>
                                                    <div class="view-more">
                                <a href="/phu-kien" class="font-button">
                                    <span>Xem thêm<i class="fa fa-angle-right"></i></span>
                                </a>
                            </div>
                                            </div>
                            </div>
         
</div>
<div class="container box-volunteer mt-16" style="display:none;"> 
<div class="homepage-influencer-slider container">
    <div class="row mt-16">
        <div class="influencer-slider-wrapper col-md-12">
            <div class="influencer-slider-content">
                <div class="influencer-background float-left"></div>
                	<div class="influencer-slider float-left p-8 pr-16">
                  	<div class="item">
                    	<a href="/tin-tuc/trac-thuy-mieu-iphone-13-pro-max-didongviet/" target="_blank" title="">
                         	<img alt="" src="">
                            <p class="font-subtitle-1"></p>
                            <div class="hover-star"></div>
                    	</a>
                	</div>
				</div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="slider-custom home-brand-slider container mt-16" style="display:none;">
    <div class="row">
        <div class="col-md-12">
            <div class="homepage-content-wrapper p-16">
                <div class="brand-slider">
                	<div class="item">
                    	<img alt="24h" src="../assets/trungdinh/images/banners/24h_1.png">
                    </div>
                    <div class="item">
                    	<img alt="Nghe nhìn" src="../assets/trungdinh/images/banners/nghenhin_1.png">
                    </div>
                       	<div class="item">
                            <img alt="Sài Gòn" src="../assets/trungdinh/images/banners/saigon_2.png">
                        </div>
                      	<div class="item">
                            <img alt="Thanh Niên" src="../assets/trungdinh/images/banners/thanhnien_2.png">
                        </div>
                       	<div class="item">
                            <img alt="Thế Giới Số" src="../assets/trungdinh/images/banners/thegioiso_1.png">
                        </div>
                       	<div class="item">
                            <img alt="VnExpress" src="../assets/trungdinh/images/banners/vnexpress_1.png">
                        </div>
            	</div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<div class="column main">
<div class="hidden">
<h1>Trung Đỉnh - Điện thoại, Laptop, Tablet, Phụ kiện Chính Hãng</h1>
</div> 
</div>
<div class="box-page">
<div class="box-page-content">
<div class="box-page-content-inner">

</div>
</div>
</div>
</div>
</template>

<script>
import LayoutHome from '../layouts/LayoutHome.vue';
import axios from 'axios'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'Home',
  components: {
  	 Carousel,
    Slide,
    Pagination,
    Navigation, 
  },
  data() {
    return {
      settings: {
      itemsToShow: 5,
      snapAlign: 'center',
    },
      breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
      fullPage: false,
      biz_resp_data: "",
      metamask_address: "",
      msg: "This is demo net work",
      trendingArtworks:[],
      liveAuctions:[],
      featuredAssets:[],
      topAuthors:[],
      hotCollections:[],
      topSellers:[],
      rate_amount:1,
      brands:[],
      homeSliders:[],
      featuredArtists:[],
      api_baseurl:"",
      categories:[],
      popular_artists:[],
      vn_popular_artists:[],
      topPicks:[],
      classes:[],
      services:null,
      itemCategories:null,
      miscData:null,
      items:null,
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  beforeCreate() {
  	console.log("beforeCreate");
  },
  async created() {
      const rs = await axios
        .get('/api/misc-data/')
        .then(response => {
          this.$store.commit('setMiscData', response.data)
          this.miscData = response.data
        })
        .catch(error => {
          console.log(error)
        })
  
	  	const srs =	await axios
	                .get("/api/posts/?group=trending&image_sizes=600x600&user_image_sizes=65x65&page_size=10&post_type=3")
	                .then(response => {
						this.$store.commit('setServices', response.data.results);
						this.services=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
		const irs = await axios
	                .get("/api/items/?group=trending&image_sizes=600x600&user_image_sizes=65x65&page_size=10")
	                .then(response => {
						this.$store.commit('setItems', response.data.results);
						this.items = response.data.results;
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })   
  },
  methods: {
    doAjax() {
        this.isLoading = true;
        // simulate AJAX
        setTimeout(() => {
        	this.isLoading = false
    	}, 5000)
    },
    onCancel() {
    	console.log('User cancelled the loader.')
    },
    async processBizname() {
      await axios
        .get('/api/process-bizname/', {
          params: {
            bizname: this.bizname
          }
        })
        .then(response => {
          this.biz_resp_data = response.data
          console.log(this.biz_resp_data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
	async getItemList() {
	  		await axios
	                .get("/api/items/?group1=trending&image_sizes1=600x600&user_image_sizes1=65x65&page_size=10")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setItems', response.data.results);
						this.items=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  async getAuctionList() {
	  		await axios
	                .get("/api/items/?group=live_auction&image_sizes=550x670")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setLiveAuctions', response.data.results);
						this.liveAuctions=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getFeaturedAssets() {
	  		await axios
	                .get("/api/items/?group=featured&image_sizes=600x600&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setFeaturedAssets', response.data.results);
						this.featuredAssets=response.data.results
						console.log(this.featuredAssets.length)
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	
	  	async getTopPicks() {
	  		await axios
	                .get("/api/items/?group=top_picks&image_sizes=600x600&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.topPicks=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	
	  	async getFeaturedArtists() {
	  		await axios
	                .get("/api/users/?group=featured_artists&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.featuredArtists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getPopularArtists() {
	  		await axios
	                .get("/api/users/?group=popular_artists&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.popular_artists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getVNPopularArtists() {
	  		await axios
	                .get("/api/users/?group=popular_artists&location=vn&image_sizes=65x65&item_image_sizes=600x600&page_size=8")
	                .then(response => {
						this.vn_popular_artists=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
	  	async getTopAuthors() {
	  		await axios
	                .get("/api/users/?group=top_authors&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						//console.log(response.data)
						this.$store.commit('setTopAuthors', response.data);
						this.topAuthors=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getTopSellers() {
	  		await axios
	                .get("/api/users/?group=top_sellers&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTopSellers', response.data.results);
						this.topSellers=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getHotCollections() {
	  		await axios
	                .get("/api/items/?group=hotcollections&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setHotCollections', response.data.results);
						//this.trendingArtworks=response.data.results
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getClasses() {
	  		await axios
	                .get("/api/classes/?page_size=6&page_size=205x205")
	                .then(response => {
	                	console.log(response.data.results);
						this.classes = response.data.results;
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
 },
 mounted() {
 	//this.getItemList()
    document.title = 'Home'
    this.api_baseurl= axios.defaults.baseURL
    this.services = JSON.parse(localStorage.getItem('services'));
    this.miscData = JSON.parse(localStorage.getItem('miscData'));
    this.items = JSON.parse(localStorage.getItem('items'));
    //this.itemCategories = JSON.parse(localStorage.getItem('itemCategories'));
    if (this.miscData==null) {
    	this.miscData = this.$store.state.miscData
    	if(this.miscData==null) {
    		setTimeout(() => {
		  		location.reload()
		  	}, 5000)
    		
    	}
    }
  },
  computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
  updated() {
  	console.log("updated");
  }
}

</script>