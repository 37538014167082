<template>
</template>
<script>
import config from '../config.js'
import VueMetamask from 'vue-metamask';
import UploadFiles from "@/components/UploadFiles";
import axios from 'axios'
import Toast from '@/components/Toast.vue'
export default {
    name: 'MintNFT',
    components: {
	    UploadFiles,
	    VueMetamask,
	    Toast
	},
	data() {
	    return {
	      item: {},
	      tokenUri:{},
	      metamaskWallet:{},
	      itemUrl:"",
	    }
	  },
    mounted() {
        document.title = 'Mint NFT';
        this.item = JSON.parse(localStorage.getItem("item"));
        this.tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
        this.metamaskWallet = localStorage.getItem("metamaskWallet");
        this.itemUrl = "https://zenart.sg/items/"+this.item.uuid+"/";
    },
    computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
    methods: {
    	onComplete(data){
	    	console.log('data:', data);
	    	console.log(data['metaMaskAddress']);
	    	if(!data['metaMaskAddress']) {
	    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
	    	}
	    	this.metamaskWallet = data
	    	this.$store.commit('setMetamaskWallet', data);
	    },
    	hideToast() {
  			this.$store.commit("hideToast");
  		},
  		
  		async mintNFT(tokenURIDataUrl) {
			//const API_URL = "https://eth-ropsten.alchemyapi.io/v2/L6DL1hRnnw_P9FeVojb-qaOxfJUbmiLU"
			const API_URL=config.apiUrl
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			
			//const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			const contract = require("../../artifacts/contracts/VONMIVERSE.sol/VONMIVERSE.json")
			console.log(JSON.stringify(contract.abi))
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(PUBLIC_KEY, "latest") //get latest nonce
			//the transaction
			const tx = {
			    from: PUBLIC_KEY,
			    to: contractAddress,
			    nonce: nonce,
			    gas: 500000,
			    data: nftContract.methods.mintNFT(PUBLIC_KEY, tokenURIDataUrl).encodeABI(),
			}
			
			const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY)
			signPromise
			    .then((signedTx) => {
			      web3.eth.sendSignedTransaction(
			        signedTx.rawTransaction,
			        function (err, hash) {
			          if (!err) {
			            this.message = "The hash of your transaction is: "+hash;
			            const tokenUri=JSON.parse(localStorage.getItem("tokenUri")); 
			            console.log(tokenUri.id)
			            console.log(hash)
			            const formData = {
			            	txn_hash:hash,
			            	id:tokenUri.id
		            	}
			            axios.put("/api/tokenuris/"+tokenUri.id+"/", formData)
			                .then(response => {
			                	console.log(response.data);
			                    const toPath = this.$route.query.to || '/create-collection/';
			                    this.$router.push(toPath);
			                    this.message = "Update the transaction hash successfully!";
			                    
			                })
			                .catch(error => {
			                    if (error.response) {
			                        for (const property in error.response.data) {
			                        	this.message = "Error the transaction hash error!";
			                        }
			                    } else {
			                        this.message = "Something went wrong. Please try again";
			                        console.log(JSON.stringify(error))
			                    }
			                })
			            
			            console.log(
			              "The hash of your transaction is: ",
			              hash,
			              "\nCheck Alchemy's Mempool to view the status of your transaction!"
			            )
			          } else {
			            console.log(
			              "Something went wrong when submitting your transaction:",
			              err
			            )
			          }
			        }
			      )
			    })
			    .catch((err) => {
			      console.log(err)
			    })
		},
		
    	async mint(){
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
    		//create token uri 
    		const tokenUriFormData = {
    			name: this.$refs.name.value,
    			address:this.$refs.address.value,
    			description: this.$refs.description.value,
    			assetURL: this.$refs.assetURL.value,
    			traits:[{}],
    			item_uuid: item.uuid,
    			sale_type: localStorage.getItem("sale_type")
	       	}
			console.log(tokenUriFormData);	
			
	        await axios
	                .post("/api/tokenuris/", tokenUriFormData)
	                .then(response => {
	                	console.log(response.data);
	                	this.message = "Create token uri successfully!";
	                	this.$store.commit('showToast', "Create token uri successfully!");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.tokenUri=JSON.stringify(response.data)
	                    localStorage.setItem("tokenUri", JSON.stringify(response.data));
	                    this.mintNFT("https://zenart.sg/tokenuris/"+this.tokenUri.id+"/");
	                    
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	        })
	                
    		this.$store.commit('showToast', "Mint NFT...");
    	},
    	reset() {
    		localStorage.removeItem("item");
    		localStorage.removeItem("tokenUri");
    		const toPath = this.$route.query.to || '/create-collection/';
	        this.$router.push(toPath);
    	},
    }
}
</script>