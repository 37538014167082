<template>
	
</template>

<script>
import axios from 'axios'

export default {
    name: 'Register',
    data() {
        return {
            full_name: '',
            email:'',
            password: '',
            password2: '',
            errors: []
        }
    },
    mounted() {
        document.title = 'Register'
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.full_name === '') {
                this.errors.push('The fullname is missing')
            }

            if (this.email === '') {
                this.errors.push('The email is missing')
            }

            if (this.password === '') {
                this.errors.push('The password is too short')
            }

            if (this.password !== this.password2) {
                this.errors.push('The passwords doesn\'t match')
            }

            if (!this.errors.length) {
                const formData = {
                    full_name: this.full_name,
                    email: this.email,
                    password: this.password,
                    re_password:this.password2,
                }

                axios
                    .post("/api/v1/users/", formData)
                    .then(response => {
                        this.$router.push('/login/')
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }

                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')
                            
                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>