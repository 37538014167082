<template>
</template>
<script>
import UploadService from "../services/UploadFilesService";
import SingleImagePreview from '@/components/SingleImagePreview.vue';
import axios from 'axios';
import datetime from 'vuejs-datetimepicker';

export default {
    name: 'MyProfileEdit',
    components: {
	    //UploadFiles,
	    SingleImagePreview,
	    datetime,
	},
	data() {
	    return {
	      is_ok: false,
	      is_cancel:true,
	      counter: 0,
	      user:{},
	      item: "",
	      txn_hash:"",
	      currencies:["USD","ETH"],
	      checkedPriceType: 'fixed-price',
	      price:0,
	      metamaskWallet:{},
	      itemUrl:"",
	      showModal: false,
	      steps:['loader','uncheck','uncheck','uncheck'],
	      startDate:"",
	      endDate:"",
	      properties:[],
	      selectedFiles: undefined,
	      selectedImages: undefined,
	      currentFile: undefined,
	      currentImage: undefined,
	      progress: 0,
	      message: "",
	      fileInfos: [],
	      showImagePreview: false,
	      showCoverImage:false,
	      showVideoPreview: false,
	      facebook:"",
	      pinterest:"",
	      twitter:"",
		  imagePreview: '',
		  file:'',
		  image:'',
		  coverImagePreview:'',
		  metamask_address: '',
		  showCoverImagePreview:false,
		  inputs: [{key:{
		  				counter:0,
			      		id: 'key0',
			      		label: 'e.g. M',
			      		value:'',
		      		},
			      	value:{
			      		counter:0,
			      		id: 'value0',
			      		label: 'e.g. M',
			      		value:'',
		      		}
		  },
		  ],
	    }
	},
    beforeCreate() {
    	this.$store.commit('initializeStore')
    	const token = this.$store.state.token
	    if (token) {
	        //axios.defaults.headers.common['Authorization'] = "Token " + token
	        axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    } else {
	        axios.defaults.headers.common['Authorization'] = ""
	    }
	    console.log(token)
    },
    mounted() {
        document.title = 'Edit My Profile';
        this.item = JSON.parse(localStorage.getItem("item"));
        this.user = JSON.parse(localStorage.getItem("user"));
        this.getUser();
        this.metamaskWallet = localStorage.getItem("metamaskWallet");
        if(localStorage.getItem("metamaskWallet")) {
        	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
    	}
        
    },
    
    methods: {
    	
    	addProperty(event) {
    		console.log(event.target.dataset.counter)
    		console.log("addProperty");
    		var key = document.querySelector("input[id=key"+event.target.dataset.counter+"]").value
    		var value = document.querySelector("input[id=value"+event.target.dataset.counter+"]").value
			
			var property_keys = document.getElementsByName("property_keys")
			var property_values = document.getElementsByName("property_values")
			
			var json_arr=[];
			
			for (var i = 0; i < property_keys.length; i++) {
				var json = {};
                var k = property_keys[i];
                var v = property_values[i];
                if(k.value && v.value) {
                	json[k.value]=v.value
                	json_arr.push(JSON.stringify(json))
                }
            }
            this.properties=json_arr;
				
    		if (key && value){
	    		this.counter +=1;
	    		this.inputs.push(
			    {key:{
		    		counter: this.counter,
			      		id: 'key'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		},
			      	value:{
			      		counter:this.counter,
			      		id: 'value'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		}
				});
	    	}
    	},
    	selectFile( event ) {
	      this.selectedFiles = this.$refs.file.files;
	      /*
					Set the local file variable to what the user has selected.
				*/
				this.file = event.target.files[0];

				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.file ){
					/*
						Ensure the file is an image file.
					*/
					console.log(this.file.type);
					console.log(this.file.type.indexOf('video/') !== -1)
					if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.file );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showImagePreview = true;
							this.showCoverImage = false;
							this.showVideoPreview = false;
							this.showCoverImagePreview = false;
							this.imagePreview = reader.result;
						}.bind(this), false);
								
					}else if (this.file.type.indexOf('video/') !== -1) {
						let video = document.getElementById('video-preview');
						let reader = new FileReader();
		
						reader.readAsDataURL( this.file );
						reader.addEventListener('load', function(){
							this.showImagePreview = false;
							this.showVideoPreview = true;
							this.showCoverImage=true;
							video.src = reader.result;
						}.bind(this), false);
					} else {
						this.showImagePreview = false;
						this.showVideoPreview = false;
						this.showCoverImage=true;
					}
				}
    },
    selectImage( event ) {
      this.selectedImages = this.$refs.image.files;
      /*
					Set the local file variable to what the user has selected.
				*/
				this.image = event.target.files[0];
				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.image ){
					/*
						Ensure the file is an image file.
					*/
					
					if ( /\.(jpe?g|png|gif)$/i.test( this.image.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.image );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showCoverImagePreview = true;
							this.coverImagePreview = reader.result;
						}.bind(this), false);
								
					}
				}
    },
    viewNFT: function () {
    	const item = JSON.parse(localStorage.getItem("item"));
    	const toPath = this.$route.query.to || '/items/'+item.uuid+"/";
    	localStorage.removeItem("item");
		this.$router.push(toPath);
    },
    deleteItemFile: function (itemfile_id) {
      const token = localStorage.getItem("token")
        axios.defaults.headers.common["Authorization"] = "Token " + token
	    
	    axios.delete("/api/itemfiles/"+itemfile_id+"/", {})
                .then(response => {
				    console.log(response.data);
				    this.message = "Delete the file successfully!";
				    this.$store.commit('showToast', "Delete the file successfully!");
				    return UploadService.getFiles("/api/itemfiles/");
                })
                .then(files => {
		          this.fileInfos = files.data.results;
		        })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
    },
    async upload() {
      this.steps=['loader','uncheck','uncheck','uncheck']
      this.progress = 0;
	  const item = JSON.parse(localStorage.getItem("item"));
	  const user = JSON.parse(localStorage.getItem("user"));
	  if (!item) {
	  	//console.log(this.checkedPriceType)
	  	
	  	if (this.checkedPriceType=='timed-auction') {
           this.price=0;
        } else if (this.checkedPriceType=='fixed-price') {
           this.price = this.$refs.price.value;
        } else {
           this.price=0;
        }
	 	const itemFormData = {
	 		title: this.$refs.title.value||"undefined",
			meta_description: this.$refs.description.value||"undefined",
			meta_keywords: this.$refs.description.value||"undefined",
			description: this.$refs.description.value||"undefined",
			content: this.$refs.description.value||"undefined",
			quantity: this.$refs.quantity.value||0,
			price: this.price||0,
			active:1,
			user:user.id,
			currency:1,
	    }
	    console.log(itemFormData)
	    
	    await axios
                .post("/api/items/", itemFormData)
                .then(response => {
                	console.log(response.data);
                	console.log(response.data.uuid);
                	this.item=JSON.stringify(response.data);
                	this.itemUrl = "https://zenart.sg/items/"+response.data.uuid+"/";
					localStorage.setItem("item", JSON.stringify(response.data));
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
    	})
	  } 
	  
	  if(this.selectedFiles) {
      this.currentFile = this.selectedFiles.item(0);
      UploadService.upload("/api/itemfiles/",this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          //this.message = "Upload the file successfully!";
          //this.$store.commit('showToast', "Upload the file successfully!");
          return UploadService.getFiles("/api/itemfiles/");
        })
        .then(files => {
          this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentFile = undefined;
        });
	
      this.selectedFiles = undefined;
      }
      
	  if(this.selectedImages) {
	 	this.currentImage = this.selectedImages.item(0);
      UploadService.upload("/api/itemimages/",this.currentImage, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          this.message = "Upload the image successfully!";
          //this.$store.commit('showToast', "Upload the image successfully!");
          return UploadService.getFiles("/api/itemimages/");
        })
        .then(files => {
          //this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentImage = undefined;
        });
	
      }
      
      return this.mint()
      
    },
    	onComplete(data){
	    	console.log('data:', data);
	    	console.log(data['metaMaskAddress']);
	    	if(!data['metaMaskAddress']) {
	    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
	    		
	    		setTimeout(function () {
			       window.location.replace('https://metamask.io/download.html'); 
			    }, 2000); 
	    	}
	    	this.metamaskWallet = data
	    	this.$store.commit('setMetamaskWallet', data);
	    	this.metamask_address=data['metaMaskAddress']
	    },
    	hideToast() {
  			this.$store.commit("hideToast");
  		},
  		
  		async processNFT() {
  			this.is_cancel=true
  			this.is_ok=false
  			this.upload()
  		},
  		async approve() {
  			this.steps=['check','check','loader','uncheck']
  			return this.putOnSale()
  		},
  		async putOnSale() {
  			this.steps=['check','check','check','loader']
  			return this.done()
  		},
  		async done() {
  			this.is_cancel=false
  			this.is_ok=true
  			this.steps=['check','check','check','check']
  		},
  		
    	reset() {
    		this.item="";
    		this.tokenUri="";
    		localStorage.removeItem("item");
    		localStorage.removeItem("tokenUri");
    		//const toPath = this.$route.query.to || '/create-collection/';
			//this.$router.push(toPath);
			window.location.replace('/create-collection/');
		
    	},
    	
    	async getCurrencies() {
    		await axios
                .get("/api/currencies/?page_size=1000")
                .then(response => {
					this.currencies = response.data.results
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async getUser() {
    		await axios
                .get("/api/users/"+this.user.uuid+"/")
                .then(response => {
                	console.log(response.data)
					this.user=response.data
					console.log("++++++++++user+++++++++++++");
					console.log(this.user.photo);
					if(this.user.photo) {
						this.showCoverImagePreview=true
					}
					console.log("++++++++++here+++++++++++++");
					this.$store.commit('setUser',response.data);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        this.$store.commit('showToast', "Something went wrong. Please try again");
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async updateUser() {
    		const photo_image = this.$refs.image.files[0];
    		const formData = new FormData();
    		var extra_data = {};
    		var social_arr = []
    		var wallet_arr = []
    		
    		social_arr.push({'name':'facebook','username':this.$refs.facebook.value,'url':'https://facebook.com/'+this.$refs.facebook.value})
    		social_arr.push({'name':'pinterest','username':this.$refs.pinterest.value,'url':'https://pinterest.com/'+this.$refs.pinterest.value})
    		social_arr.push({'name':'twitter','username':this.$refs.twitter.value,'url':'https://twitter.com/'+this.$refs.twitter.value})
    		
    		extra_data['socials'] = social_arr
    		extra_data['wallets'] = wallet_arr 
    		
    		formData.append("full_name",this.$refs.full_name.value);
    		formData.append("phone_number",this.$refs.phone_number.value);
    		if (photo_image) {
    			formData.append("photo",photo_image);
    		}
			formData.append("extra_data",JSON.stringify(extra_data));    
					
    		//const formData = {
		    //	full_name: this.$refs.full_name.value,
			//	phone_numer: this.$refs.phone_number.value,
			//	photo: this.$refs.image.files[0],	
	    	//}
	    	console.log(extra_data)
	    	console.log(formData)
	    	
	    	 axios.put("/api/users/"+this.user.uuid+"/", formData,{
			    headers: {
			      'Content-Type': 'multipart/form-data'
			    }
			}).then(response => {
				console.log(response.data);
				const toPath = this.$route.query.to || '/create-collection/';
				//this.$router.push(toPath);
				this.message = "Update the user profile successfully!";
				this.$store.commit('showToast', "Update the user profile successfully!");
				this.user=response.data
				this.$store.commit('setUser',response.data);
		   })
		   .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	    	
    	},
    }
}
</script>