<template>
	<div class="breadcrumbs">
	    <ul class="items">
	        <li class="item"><a href="/">Trang chủ</a></li>
	     	<li class="item"><a title="" href="/items/">Tất cả</a></li>
		</ul>
	</div>
	<main id="maincontent" class="page-main">
		<div class="page messages"></div>
		<div class="category-slideshow-full" style="display:none;">
			<div class="category-slideshow-right">
				<div class="box-list-bn">
				</div>
			</div>
		</div>
		<div class="columns">
			<div class="row">
				<div class="content-wrapper">
					<div class="column main">
 						<div class="page-main">
  							<div class="filter-all-category">
							</div> 
						</div>
					</div>
					<div class="products wrapper grid products-grid"> 
        				<h1 class="page-title" id="page-title-heading">Điện thoại</h1>        
        				<ol class="products list items product-items" v-if="this.items">
                        	<li class="item product product-item" v-for="object in this.items">
				                <div class="product-item-info" data-container="product-grid">
					                <div class="product-item-image">
					                    <div class="product-label" style="display:none;"><span class="percent-deal"></span></div>                                    
					                    <a :title="object.title" class="product-item-link" :href="'/items/'+object.slug+'/'">
						                    <span>
						                        <img :alt="object.title" :src="object.featured_image_url">
						                    </span>
					                	</a>
					            	</div>
				                    <div class="product details product-item-details">
				                    	<h3 class="product name product-item-name lh-15">
				                        	<a :title="object.title" class="product-item-link" :href="'/items/'+object.slug+'/'">{{object.title}}</a>
				            			</h3>
				                         <div class="box-price-product">
				                    		<div class="price-box price-final_price" data-role="priceBox" data-product-id="16591" data-price-box="product-id-16591">
					                    		<span class="normal-price">
													<span class="price-container price-final_price tax weee">
												   	<span class="price-label">Mua ngay</span>
												        <span id="product-price-16591" data-price-amount="21190000" data-price-type="finalPrice" class="price-wrapper ">
												        	<span class="price">{{object.price}} {{object.currency_symbol}}</span>
												        	<span class="discount"></span>
												        	<span class="market-price" v-if="object.old_price > object.price">{{object.old_price}} {{object.currency_symbol}}</span>        
												        </span>
											        </span>
					   							</span>
					   						</div>                    
					   						<div class="col-installment">
					   							<span class="price-label"></span>
					   							<span class="price">{{object.price}} {{object.currency_symbol}}</span>
					   						</div>
					   					</div>
					   					<div class="box-product-bottom" style="display:none;">
				                    		<div class="item-product-info">
				                            	<span class="label-special-gift gift-lll"></span>                    
				                           	</div>              
				            			</div>                       
				                    </div>
				                </div>
				            </li>
                    	</ol>
					</div>
   
  
			<div class="category-view">    
				<div class="category-description">
			    	<div class="full-text min-full-text">
			        	<h2>Điện thoại di động, Smartphone: Tất cả điều bạn cần biết khi mua</h2>
					</div>
			    </div>
			</div>
		</div>
	</div>
</div>
</main>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ItemList',
    components: {
    },
    data() {
        return {
            items:null,
            count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	      	fullPage:false,
        }
    },
    mounted() {
        this.getItemList()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'ItemList') {
                this.getItemList()
            }
        }
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getItemList()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getItemList()
    	},
        async getItemList() {
        	if (!this.currentPage) {
				this.currentPage = 1
			} 
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
            });
                
            const category = this.$route.query.category
			const collection = this.$route.query.collection
			const type = this.$route.query.type
			const tag = this.$route.query.tag
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/items/?'+"page="+this.currentPage+"&page_size=15")
                .then(response => {
                	console.log(response.data.results)
					this.items	= response.data.results				
                    if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	 setTimeout(() => {
		                    loader.hide()
		                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>