<template>
</template>
<script>
import axios from 'axios'
import Toast from '@/components/Toast.vue'
import config from '../config.js'

export default {
    name: 'MyProfile',
    components: {
  	},
    data() {
	    return {
	      itemList: [],
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
	      user:{},
	      metamaskWallet:{},
	      metamask_address:"",
	      api_baseurl:"",
	      rate_amount:1,
	    }
	  },
	beforeCreate() {
    	this.$store.commit('initializeStore')
    	const token = this.$store.state.token
	    if (token) {
	        //axios.defaults.headers.common['Authorization'] = "Token " + token
	        axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    } else {
	        axios.defaults.headers.common['Authorization'] = ""
	    }
    },
   mounted() {
   		document.title = 'My Profile';
    	this.api_baseurl= axios.defaults.baseURL
    	this.getItemList();
        this.rate_amount=this.$store.state.rate.amount
        
		this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
	  	console.log(this.metamaskWallet);
	    if(this.metamaskWallet){
	    	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
	    }
	    console.log(this.metamask_addresss);
    },
    methods: {
    	goto(url){
			window.location.href = url
		},
    	
    	async allowBuy(tokenId,price) {
    		this.$store.commit('setIsLoading', true)
    		console.log(tokenId)
    		console.log(price)
    		console.log(this.metamask_address)
    		
        	//const API_URL="https://eth-ropsten.alchemyapi.io/v2/mkmpYPZHBR0zola6xOzx4A8E5XCcrbcI"
        	const API_URL=config.apiUrl
        	const MYWALLET_ADDRESS = this.metamask_address
        	//const MYWALLET_ADDRESS = this.metamaskWallet['metaMaskAddress']
        	const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			const ETH_PRICE = (1000000000000000000*price).toFixed(0).toString();
			console.log(ETH_PRICE);
			
			const contract = require("../../artifacts/contracts/VONMIVERSE.sol/VONMIVERSE.json")
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(MYWALLET_ADDRESS, "latest") //get latest nonce
			nftContract.methods.allowBuy(tokenId,ETH_PRICE).send({from:MYWALLET_ADDRESS});
			
			
			
			this.$store.commit('setIsLoading', false)
        },
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getItemList()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getItemList()
    	},
    	async getItemList() {
    		this.$store.commit('setIsLoading', true)
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            const user = JSON.parse(localStorage.getItem("user"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
			if (!this.currentPage) {
				this.currentPage = 1
			} 
			
			//console.log(user.id)
			
	        await axios.get("/api/items/?user_id="+user.id+"&page="+this.currentPage+"&page_size=6")
	                .then(response => {
	                	if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	console.log(response.data);
	                	console.log(response.data.results);
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	this.$store.commit('showToast', "Get item list");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.itemList=response.data.results;
	                    if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    localStorage.setItem("itemList", response.data.results);
	                    this.$store.commit('setIsLoading', false)     
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.$store.commit('showToast', "Something went wrong. Please try again");
	                        console.log(JSON.stringify(error))
	                    }
	        })
	           
    		this.$store.commit('showToast', "Get Item List...");
    		
    	},
    }
}
</script>