<template>
</template>
<script>
import axios from 'axios'
export default {
    name: 'ClassList',
  	data() {
	    return {
	    	classes:[],
	    	api_baseurl:"",
	    	count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Class List'
        this.getClasses()
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getClasses()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getClasses()
    	},
    	async getClasses() {
    		if (!this.currentPage) {
				this.currentPage = 1
			} 
	  		await axios
	                .get("/api/classes/?page="+this.currentPage+"&page_size=6")
	                .then(response => {
						console.log(response.data.results)
						this.classes = response.data.results
						
						if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>