<template>
</template>

<script>
import axios from 'axios'

export default {
    name: 'TokenUri',
    components: {
    },
    data() {
        return {
            tokenuri: {},
            api_baseurl: "",
            format:"html",
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getTokenUri()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'TokenUri') {
                this.getTokenUri()
            }
        }
    },
    methods: {
        async getTokenUri() {
            const tokenuriSlug = this.$route.params.tokenuri_slug
            this.$store.commit('setIsLoading', true)
			const format = this.$route.query.format
			this.format=format
            axios
                .get(`/api/tokenuris/${tokenuriSlug}/`)
                .then(response => {
                    this.tokenuri = response.data
                    document.title = this.tokenuri.name 
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>