<template>
	<div class="breadcrumbs-wrapper">
        <div class="breadcrumbs">
            <div class="row">
                <ul class="items col-md-12">
                	<li class="item home">
                     	<a href="/" title="Trang chủ">Trang chủ</a>
                   	</li>
              		<li class="item cms_page">
                    	<strong>{{ page.title }}</strong>
             		</li>
            	</ul>
            </div>
        </div>
    </div>		
	<main id="maincontent" class="page-main">    
		<div class="columns">
			<div class="box-page">
				<div class="sidebar sidebar-main">
					<ul>
						<li v-for="aboutmenu in this.$store.state.aboutMenuitems"><a :href="aboutmenu.link_url">{{aboutmenu.title}}</a></li>
					</ul>
				</div>
				<div class="box-page-content">
					<div class="box-page-content-inner">
						<h1>{{ page.title }}</h1>
						<div v-html="page.content"/>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Page',
    components: {
    },
    data() {
        return {
        	fullPage: false,
            page: {},
            miscData:{},
        }
    },
    mounted() {
    	document.title = 'Page loading';
    	this.miscData = JSON.parse(localStorage.getItem("miscData"));
        this.getPage();
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Page') {
                this.getPage()
            }
        }
    },
    methods: {
            onCancel() {
                console.log('User cancelled the loader.')
            },
        async getPage() {
            const pageSlug = this.$route.params.page_slug
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                    loader:'dots',
                });

            axios
                .get(`/api/pages/${pageSlug}/`)
                .then(response => {
                    this.page = response.data
                    document.title = this.page.title
                    setTimeout(() => {
	                    loader.hide()
	                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })

        }
    }
}
</script>