<template>
	<div class="breadcrumbs-wrapper">
        <div class="breadcrumbs">
            <div class="row">
                <ul class="items col-md-12">
                	<li class="item home">
                     	<a href="/" title="Trang chủ">Trang chủ</a>
                   	</li>
              		<li class="item cms_page">
                    	<strong>Dịch vụ</strong>
             		</li>
            	</ul>
            </div>
        </div>
    </div>	
    <main id="maincontent" class="page-main">
    	<div class="columns">
			<div class="box-page">
				<div class="box-page-content">
						<div class="container">
							<div class="row">
								<div v-for="post in posts" class="col-md-6 col-lg-6" style="list-style:none !important;">
									<figure :style="'background-image: url('+post.featured_image+'); display: block;'">
									</figure>
									<summary>
										<h2 class="margin margin-s"><a :href="'/posts/'+post.slug+'/'">{{post.title}}</a></h2>
									   	<p class="margin margin-s margin-bottom" v-html="post.description"></p>
									</summary>
								</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import axios from 'axios'
export default {
    name: 'PostList',
  	data() {
	    return {
	    	posts:[],
	    	api_baseurl:"",
	    	count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	      	fullPage:false,
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Blog'
        this.getPosts()
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getPosts()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getPosts()
    	},
    	async getPosts() {
    		if (!this.currentPage) {
				this.currentPage = 1
			} 
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                });
            
            const typeSlug = this.$route.query.type
            console.log(typeSlug)
                
	  		await axios
	                .get("/api/posts/?type="+typeSlug+"&page="+this.currentPage+"&page_size=6")
	                .then(response => {
						console.log(response.data.results)
						this.posts = response.data.results
						
						if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	 setTimeout(() => {
		                    loader.hide()
		                }, 1000)
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>