<template>
</template>
<script>
import UploadService from "../services/UploadFilesService";
import SingleImagePreview from '@/components/SingleImagePreview.vue';
import axios from 'axios';
import Toast from '@/components/Toast.vue';
import datetime from 'vuejs-datetimepicker';
import config from '../config.js';

export default {
    name: 'CreateCollection',
    components: {
	    //UploadFiles,
	    SingleImagePreview,
	    Toast,
	    datetime,
	},
	data() {
	    return {
	      metamask_type:"",
	      is_ok: false,
	      is_cancel:true,
	      counter: 0,
	      item: "",
	      txn_hash:"",
	      tokenUri:"",
	      currencies:[],
	      itemCategories:[],
	      checkedPriceType: 'fixed-price',
	      category:1,
	      currency:1,
	      price:0,
	      metamaskWallet:{},
	      itemUrl:"",
	      showModal: false,
	      steps:['loader','uncheck','uncheck','uncheck'],
	      startDate:"",
	      endDate:"",
	      properties:[],
	      selectedFiles: undefined,
	      selectedImages: undefined,
	      currentFile: undefined,
	      currentImage: undefined,
	      progress: 0,
	      message: "",
	      fileInfos: [],
	      showImagePreview: false,
	      showCoverImage:false,
	      showVideoPreview: false,
		  imagePreview: '',
		  file:'',
		  image:'',
		  coverImagePreview:'',
		  metamask_address: '',
		  accessToken:"",
      	  refreshToken:"",
		  showCoverImagePreview:false,
		  inputs: [{key:{
		  				counter:0,
			      		id: 'key0',
			      		label: 'e.g. M',
			      		value:'',
		      		},
			      	value:{
			      		counter:0,
			      		id: 'value0',
			      		label: 'e.g. M',
			      		value:'',
		      		}
		  },
		  ],
	    }
	},
    beforeCreate() {
    	this.$store.commit('initializeStore')
    	const token = this.$store.state.token
	    if (token) {
	        //axios.defaults.headers.common['Authorization'] = "Token " + token
	        axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    } else {
	        axios.defaults.headers.common['Authorization'] = ""
	    }
	    console.log(axios.defaults.headers.common['Authorization'])
	    console.log(token)
    },
    mounted() {
        document.title = 'Create Collection';
        console.log(this.metamask_address);
        this.item = JSON.parse(localStorage.getItem("item"));
        this.tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
        this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
        this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    	this.refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
        //state.metamaskWallet
        console.log( this.metamaskWallet['metaMaskAddress']);
        
        this.metamask_type=this.metamaskWallet['type']
    	if (!this.$store.state.isRightNetwork) {
    		this.$store.commit('showToast', 'Please choose Ropsten Test Network!');
    	}
        
        if(this.metamaskWallet){
        	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
        }
        console.log(this.metamask_address);
        if(this.tokenUri) {
        	this.checkedPriceType = this.tokenUri.sale_type;
        }
        console.log(this.tokenUri);
        if (this.item) {
        	this.itemUrl = "https://zenart.sg/items/"+this.item.uuid+"/";
        }
        this.getCurrencies();
        this.getItemCategories();
        
        this.verifyToken(this.accessToken,this.refreshToken);
        
        const token = this.$store.state.token
        console.log(token)
        
	    UploadService.getFiles("/api/itemfiles/").then(response => {
	      this.fileInfos = response.data.results;
	    });
    },
    computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
    
    methods: {
    	
    	addProperty(event) {
    		console.log(event.target.dataset.counter)
    		console.log("addProperty");
    		var key = document.querySelector("input[id=key"+event.target.dataset.counter+"]").value
    		var value = document.querySelector("input[id=value"+event.target.dataset.counter+"]").value
			
			var property_keys = document.getElementsByName("property_keys")
			var property_values = document.getElementsByName("property_values")
			
			var json_arr=[];
			
			for (var i = 0; i < property_keys.length; i++) {
				var json = {};
                var k = property_keys[i];
                var v = property_values[i];
                if(k.value && v.value) {
                	json[k.value]=v.value
                	json_arr.push(JSON.stringify(json))
                }
            }
            this.properties=json_arr;
				
    		if (key && value){
	    		this.counter +=1;
	    		this.inputs.push(
			    {key:{
		    		counter: this.counter,
			      		id: 'key'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		},
			      	value:{
			      		counter:this.counter,
			      		id: 'value'+this.counter,
			      		label: 'e.g. M',
			      		value:'',
			      		focus:true
		      		}
				});
	    	}
    	},
    	selectFile( event ) {
	      this.selectedFiles = this.$refs.file.files;
	      /*
					Set the local file variable to what the user has selected.
				*/
				this.file = event.target.files[0];

				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.file ){
					/*
						Ensure the file is an image file.
					*/
					console.log(this.file.type);
					console.log(this.file.type.indexOf('video/') !== -1)
					if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.file );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showImagePreview = true;
							this.showCoverImage = false;
							this.showVideoPreview = false;
							this.showCoverImagePreview = false;
							this.imagePreview = reader.result;
						}.bind(this), false);
								
					} else if (this.file.type.indexOf('video/') !== -1) {
						let video = document.getElementById('video-preview');
						let reader = new FileReader();
		
						reader.readAsDataURL( this.file );
						reader.addEventListener('load', function(){
							this.showImagePreview = false;
							this.showVideoPreview = true;
							this.showCoverImage=true;
							video.src = reader.result;
						}.bind(this), false);
					} else {
						this.showImagePreview = false;
						this.showVideoPreview = false;
						this.showCoverImage=true;
					}
				}
    },
    selectImage( event ) {
      this.selectedImages = this.$refs.image.files;
      /*
					Set the local file variable to what the user has selected.
				*/
				this.image = event.target.files[0];
				/*
					Initialize a File Reader object
				*/
				let reader  = new FileReader();
				
				/*
					Check to see if the file is not empty.
				*/
				if( this.image ){
					/*
						Ensure the file is an image file.
					*/
					
					if ( /\.(jpe?g|png|gif)$/i.test( this.image.name ) ) {
						
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( this.image );
						/*
							Add an event listener to the reader that when the file
							has been loaded, we flag the show preview as true and set the
							image to be what was read from the reader.
						*/
						reader.addEventListener("load", function () {
							this.showCoverImagePreview = true;
							this.coverImagePreview = reader.result;
						}.bind(this), false);
								
					}
				}
    },
    viewNFT: function () {
    	const item = JSON.parse(localStorage.getItem("item"));
    	const toPath = this.$route.query.to || '/items/'+item.uuid+"/";
    	localStorage.removeItem("item");
    	localStorage.removeItem("tokenUri");
		this.$router.push(toPath);
    },
    deleteItemFile: function (itemfile_id) {
      const token = localStorage.getItem("token")
        //axios.defaults.headers.common["Authorization"] = "Token " + token
	    axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    axios.delete("/api/itemfiles/"+itemfile_id+"/", {})
                .then(response => {
				    console.log(response.data);
				    this.message = "Delete the file successfully!";
				    this.$store.commit('showToast', "Delete the file successfully!");
				    return UploadService.getFiles("/api/itemfiles/");
                })
                .then(files => {
		          this.fileInfos = files.data.results;
		        })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
    async upload() {
      this.steps=['loader','uncheck','uncheck','uncheck']
      this.progress = 0;
	  const item = JSON.parse(localStorage.getItem("item"));
	  const user = JSON.parse(localStorage.getItem("user"));
	  if (!item) {
	  	//console.log(this.checkedPriceType)
	  	
	  	if (this.checkedPriceType=='timed-auction') {
           this.price=0;
        } else if (this.checkedPriceType=='fixed-price') {
           this.price = this.$refs.price.value;
        } else {
           this.price=0;
        }
        console.log(this.currency)
        console.log(this.category)
        
	 	const itemFormData = {
	 		title: this.$refs.title.value||"undefined",
			meta_description: this.$refs.description.value||"undefined",
			meta_keywords: this.$refs.description.value||"undefined",
			description: this.$refs.description.value||"undefined",
			content: this.$refs.description.value||"undefined",
			quantity: this.$refs.quantity.value||1,
			price: this.price||0,
			active:1,
			user:user.id,
			currency:this.currency,
			categories:[this.category],
			
	    }
	    console.log(itemFormData)
	    this.verifyToken(this.accessToken,this.refreshToken)
	    await axios
                .post("/api/items/", itemFormData)
                .then(response => {
                	console.log(response.data);
                	console.log(response.data.uuid);
                	this.item=JSON.stringify(response.data);
                	this.itemUrl = "https://zenart.sg/items/"+response.data.uuid+"/";
					localStorage.setItem("item", JSON.stringify(response.data));
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
	  } 
	  
	  if(this.selectedFiles) {
      this.currentFile = this.selectedFiles.item(0);
      UploadService.upload("/api/itemfiles/",this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          //this.message = "Upload the file successfully!";
          //this.$store.commit('showToast', "Upload the file successfully!");
          return UploadService.getFiles("/api/itemfiles/");
        })
        .then(files => {
          this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentFile = undefined;
        });
	
      this.selectedFiles = undefined;
      }
      
	  if(this.selectedImages) {
	 	this.currentImage = this.selectedImages.item(0);
      UploadService.upload("/api/itemimages/",this.currentImage, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
          this.message = response.data.message;
          this.message = "Upload the image successfully!";
          //this.$store.commit('showToast', "Upload the image successfully!");
          return UploadService.getFiles("/api/itemimages/");
        })
        .then(files => {
          //this.fileInfos = files.data.results;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.$store.commit('showToast', "Could not upload the file!");
          this.currentImage = undefined;
        });
	
      }
      
      return this.mint()
      
    },
  		
  		async processNFT() {
  			this.is_cancel=true
  			this.is_ok=false
  			this.upload()
  		},
  		async approve() {
  			this.steps=['check','check','loader','uncheck']
  			
  			return this.putOnSale()
  		},
  		async putOnSale() {
  			this.steps=['check','check','check','loader']
  			return this.done()
  		},
  		async done() {
  			this.is_cancel=false
  			this.is_ok=true
  			this.steps=['check','check','check','check']
  		},
  		async mintNFT(tokenURIDataUrl) {
  			this.verifyToken(this.accessToken,this.refreshToken)
  			this.steps=['check','loader','uncheck','uncheck']
			//const API_URL = "https://eth-ropsten.alchemyapi.io/v2/L6DL1hRnnw_P9FeVojb-qaOxfJUbmiLU"
			const API_URL=config.apiUrl
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			const MYWALLET_ADDRESS = this.metamask_address
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			//const fValue = parseFloat(1000000000000000000*this.price/this.$store.state.rate.amount).toFixed(0).toString();
			//const fValue=parseFloat(this.price/this.$store.state.rate.amount).toString();
			//console.log(fValue);
			//const fValue = 1000000000000000000*this.item.price/this.$store.state.rate.amount;
			//const ETH_PRICE = (1000000000000000000*this.price/this.$store.state.rate.amount).toFixed(0).toString();//USD
			
			let ETH_PRICE = (1000000000000000000*this.price).toFixed(0).toString();
			if(this.currency==174) {
				console.log("Matic");
				ETH_PRICE = (1000000000000000000*this.price).toFixed(0).toString();
			} else if(this.currency==173) {
				let crypto_rate = this.$store.state.rate.rates['ETH'];
				ETH_PRICE = (1000000000000000000*this.price/crypto_rate).toFixed(0).toString();//ETH
			} else if(this.currency==172) {
				let crypto_rate = this.$store.state.rate.rates['BTC'];
				ETH_PRICE = (1000000000000000000*this.price/crypto_rate).toFixed(0).toString();//BTC
			}
			//const ETH_PRICE = web3.utils.toWei(WEI_PRICE, 'ether'); 
			//console.log(web3.utils.toWei(fValue, 'ether'))
			//const ETH_PRICE = web3.utils.toWei(fValue, 'ether');
			const royaltyAmount = this.$refs.royalties.value||0;
			
			console.log(ETH_PRICE)
			//const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			const contract = require("../../artifacts/contracts/VONMIVERSE.sol/VONMIVERSE.json")
			console.log(JSON.stringify(contract.abi))
			//const contractAddress = "0xd0F6413e284Ee26D5dDCd9F3A2e1b90A0E6215EA" is first 
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			//const nonce = await web3.eth.getTransactionCount(PUBLIC_KEY, "latest") //get latest nonce
			const nonce = await web3.eth.getTransactionCount(MYWALLET_ADDRESS, "latest") //get latest nonce
			//the transaction
			const tx = {
			    from: MYWALLET_ADDRESS,
			    to: contractAddress,
			    nonce: nonce,
			    gas: 500000,
			    data: nftContract.methods.mintNFT(MYWALLET_ADDRESS, tokenURIDataUrl,ETH_PRICE,royaltyAmount*100,250).encodeABI(),
			    //data: nftContract.methods.mint(MYWALLET_ADDRESS,MYWALLET_ADDRESS, 10000,tokenURIDataUrl,ETH_PRICE).encodeABI(),
			}
			
			await web3.eth.sendTransaction(
			        tx,
			        function (err, hash) {
			         console.log(err);
			         
			          if (!err) {
			            this.message = "The hash of your transaction is: "+hash;
						
						console.log(
			              "The hash of your transaction is: ",
			              hash,
			              "\nCheck Alchemy's Mempool to view the status of your transaction!"
			            )
			            let varTokenId=0
						const interval = setInterval(function() {
						        console.log("Attempting to get transaction receipt...");
						        web3.eth.getTransactionReceipt(hash, function(err, rec) {
						          console.log(err);
						          if (rec) {
						            console.log(rec);
						            let logs = rec.logs;
								    console.log(logs);
								    varTokenId = web3.utils.hexToNumber(logs[0].topics[3]);
								    //nftContract.methods.allowBuy(varTokenId,ETH_PRICE).send({from:MYWALLET_ADDRESS});
								    
								    const tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
								    const item = JSON.parse(localStorage.getItem("item"))
								    
								    if(varTokenId) {
								    	localStorage.tokenId=varTokenId
						                localStorage.setItem("tokenId",varTokenId)
						                
								    	const formData = {
							            	txn_hash:hash,
							            	id:tokenUri.id,
							            	item_uuid:item.uuid,
							            	tokenId:varTokenId,
							            	allow_buy_price:ETH_PRICE,
						            	}
						            	console.log(formData)
						            	
							            axios.put("/api/tokenuris/"+tokenUri.id+"/", formData)
						                .then(response => {
						                	console.log(response.data);
						                    const toPath = this.$route.query.to || '/create-collection/';
						                    console.log(varTokenId)
						                    //this.$router.push(toPath);
						                })
						                .catch(error => {
						                	console.log(error);
						                    if (error.response) {
						                        for (const property in error.response.data) {
						                            console.log(error.response.data[property])
						                        }
						                    } else {
						                        console.log(JSON.stringify(error))
						                    }
						                })
								    }
								    
								    clearInterval(interval);
								    
						          }
						        });
						}, 1000);
						
						
						
			          } else {
			            console.log(
			              "Something went wrong when submitting your transaction:",
			              err
			            )
			          }
			        }
			)
			const intervalToken = setInterval(function() {
				console.log(localStorage.tokenId)
				
				const tokenId = localStorage.getItem("tokenId")
				console.log(tokenId)
				if(tokenId) {
					clearInterval(intervalToken);
				}
				console.log("TokenId")
			}, 1000);
			
			const tokenId = localStorage.getItem("tokenId")
			console.log(tokenId)
			while(!localStorage.getItem("tokenId")) {
				setTimeout(()=>{
				  console.log("Wait for token");
				},1000)
			}
			return this.approve()
			
			
		},
		
    	async mint(){
    		console.log(this.checkedPriceType)
    		this.steps=['check','loader','uncheck','uncheck']
    		
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            //axios.defaults.headers.common["Authorization"] = "Token " + token
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
    		//create token uri 
    		const startDate=null
    		const endDate=null
	        if (this.checkedPriceType=='timed-auction') {
    			const startDate = document.querySelector("input[name=startDate]").value
    			const endDate = document.querySelector("input[name=endDate]").value
    		} 
    		const tokenUriFormData = {
    			name: item.title,
    			address: this.metamask_address,
    			description: item.description,
    			assetURL: this.itemUrl,
    			traits:this.properties,
    			item_uuid: item.uuid,
    			sale_type: this.checkedPriceType,
    			start_date: startDate,
    			end_date: endDate,
    			royalties: this.$refs.royalties.value||0,
    			properties:this.properties,
    			allow_buy_price:0,
	       	}
			console.log(tokenUriFormData);	
			this.verifyToken(this.accessToken,this.refreshToken)
			
	        await axios
	                .post("/api/tokenuris/", tokenUriFormData)
	                .then(response => {
	                	console.log(response.data);
	                	this.message = "Create token uri successfully!";
	                	//this.$store.commit('showToast', "Create token uri successfully!");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.tokenUri=response.data
	                    
	                    //localStorage.setItem("tokenUri", JSON.stringify(response.data));
	                    this.$store.commit('setTokenUri',response.data);
	                    console.log("https://zenart.sg/tokenuris/"+this.tokenUri.id+"/?format=json");
	                    const tokenuriLink = "https://zenart.sg/tokenuris/"+this.tokenUri.id+"/?format=json";
	                    
	                    return this.mintNFT(tokenuriLink);
	                    
	                })
	                .catch(error => {
	                	console.log(error);
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            console.log(error.response.data[property])
	                        }
	                    } else {
	                        console.log(JSON.stringify(error))
	                    }
	                })
	                
    	},
  		
    	reset() {
    		this.item="";
    		this.tokenUri="";
    		localStorage.removeItem("item");
    		localStorage.removeItem("tokenUri");
    		//const toPath = this.$route.query.to || '/create-collection/';
			//this.$router.push(toPath);
			window.location.replace('/create-collection/');
		
    	},
    	async getItemCategories() {
    		await axios
                .get("/api/itemcategories/?page_size=1000")
                .then(response => {
					this.itemCategories = response.data.results
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async getCurrencies() {
    		await axios
                .get("/api/currencies/?page_size=1000&type=crypto")
                .then(response => {
					this.currencies = response.data.results
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async submitCreateItemForm() {
            const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            //axios.defaults.headers.common["Authorization"] = "Token " + token
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
            if(item) {
            	this.itemUrl = "https://zenart.sg/items/"+item.uuid+"/";
            	
            	if (this.checkedPriceType=='timed-auction') {
            		this.price=0;
            	} else if (this.checkedPriceType=='fixed-price') {
            		this.price = this.$refs.price.value;
            	} else {
            		this.price=0;
            	}
            	
	            const formData = {
		            	title: this.$refs.title.value,
					    meta_description: this.$refs.description.value,
					    meta_keywords: this.$refs.description.value,
					    description: this.$refs.description.value,
					    content: this.$refs.description.value,
					    quantity: this.$refs.quantity.value||1,
					    price: this.price,
					    active:1,
					    id:item.id,
					    uuid:item.uuid,
					    currency:this.currency,
						categories:[this.category],
	            }
	            this.verifyToken(this.accessToken,this.refreshToken)
	            await axios
	                .put("/api/items/"+item.uuid+"/", formData)
                .then(response => {
                	console.log(response.data);
                	localStorage.setItem("item", JSON.stringify(response.data));
                    const toPath = this.$route.query.to || '/create-collection/';
                    //this.$router.push(toPath);
                    this.message = "Update the item successfully!";
                	//this.$store.commit('showToast', "Update the file successfully!");
                    this.mint()
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
            } else {
            	if (this.checkedPriceType=='timed-auction') {
            		this.price=0;
            	} else if (this.checkedPriceType=='fixed-price') {
            		this.price = this.$refs.price.value;
            	} else {
            		this.price=0;
            	}
            	const formData = {
	            	title: this.$refs.title.value,
					meta_description: this.$refs.description.value,
					meta_keywords: this.$refs.description.value,
					description: this.$refs.description.value,
					content: this.$refs.description.value,
					quantity: this.$refs.quantity.value||1,
					price: this.price,
					active:1,
					currency:this.currency,
					categories:[this.category],
	            }
				this.verifyToken(this.accessToken,this.refreshToken)
	            await axios
	                .post("/api/items/", formData)
	                .then(response => {
	                    localStorage.setItem("item", JSON.stringify(response.data));
	                    this.itemUrl = "https://zenart.sg/items/"+response.data.uuid+"/";
	                	console.log(response.data);
	                	this.message = "Create the item successfully!";
	                	//this.$store.commit('showToast', "Create the file successfully!");
	                    //const toPath = this.$route.query.to || '/create-collection/';
	                    //this.$router.push(toPath);
	                    return this.mint()
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
            }
        }
    }
}
</script>