<template>
<div class="page-wrapper">
	<header class="page-header">
		<div class="panel wrapper">
			<div class="panel header">
				<div class="header-banner ">
        			<div class="header-banner-wrapper col-md-12">
            			<div class="container"> 
                            <a href="/">
                    			<img alt="" src="/assets/trungdinh/images/fixit-top.jpg">
                			</a>
                        </div>
        			</div>
				</div>
			</div>
		</div>
		<div class="header content">
<div class="logo">
	<span data-action="toggle-nav" class="action nav-toggle"><span>Menu</span></span>
	<a href="/" title="Di động Trung Đỉnh" aria-label="store logo">
		<img src="/assets/trungdinh/images/logo.png" title="Di động Trung Đỉnh" alt="DDi động Trung Đỉnh" width="165" height="80"    />
	</a>
</div>
<div data-block="minicart" class="minicart-wrapper">
    <a class="action showcart" href="/cart/"
       data-bind="scope: 'minicart_content'">
        <span class="text">My Cart</span>
        <span class="counter qty empty"
              data-bind="css: { empty: !!getCartParam('summary_count') == false && !isLoading() }, blockLoader: isLoading">
            <span class="counter-number"><!-- ko text: getCartParam('summary_count') --><!-- /ko --></span>
            <span class="counter-label">
            <!-- ko if: getCartParam('summary_count') -->
                <!-- ko text: getCartParam('summary_count') --><!-- /ko -->
                <!-- ko i18n: 'items' --><!-- /ko -->
            <!-- /ko -->
            </span>
        </span>
    </a>
            <div class="block block-minicart"
             data-role="dropdownDialog"
             data-mage-init='{"dropdownDialog":{
                "appendTo":"[data-block=minicart]",
                "triggerTarget":".showcart",
                "timeout": "2000",
                "closeOnMouseLeave": false,
                "closeOnEscape": true,
                "triggerClass":"active",
                "parentClass":"active",
                "buttons":[]}}'>
            <div id="minicart-content-wrapper" data-bind="scope: 'minicart_content'">
                <!-- ko template: getTemplate() --><!-- /ko -->
            </div>
                    </div>
</div>


<div class="top-main-info">
    <div class="main-info-item info-hotline">
        <p class="img float-left"></p>
        <div class="info float-left">
            <a href="tel:+84903188128">
                <p class="sub">Hotline hỗ trợ</p>
                <strong class="title">0903188128</strong>
            </a>
        </div>
    </div>
    <div class="main-info-item info-customer">
        <p class="img float-left"></p>
        <ul class="header links">    <li class="authorization-link" data-label="or">
        <a href="https://trungdinh.com/customer/account/login/referer/aHR0cHM6Ly9kaWRvbmd2aWV0LnZuLw%2C%2C/" class="customer-login-link">
            Đăng nhập        </a> /
    </li>
<li><a href="https://trungdinh.com/customer/account/create/" class="customer-register-link" >Đăng ký</a></li></ul>        <p class="fs-20 bold ">
                            <a class="color-white" href="https://trungdinh.com/customer/account/">
                    Thành Viên                </a>
                        </p>
    </div>
</div>
<div class="header-top-middle"><div class="block block-search">
    <div class="block block-title"><strong>Tìm kiếm</strong></div>
    <div class="block block-content">
        <form class="form minisearch" id="search_mini_form"
              action="https://trungdinh.com/catalogsearch/result/" method="get">
            <div class="field search">
                <label class="label" for="search" data-role="minisearch-label">
                    <span>Tìm kiếm</span>
                </label>
                <div class="control">
                    <input id="search"   onkeyup="suggestSearch(this);"
                                               type="text"
                           name="q"
                           value=""
                           placeholder="B&#x1EA1;n&#x20;mu&#x1ED1;n&#x20;t&#xEC;m&#x20;g&#xEC;..."
                           class="input-text"
                           maxlength="128"
                           role="combobox"
                           aria-haspopup="false"
                           aria-autocomplete="both"
                           autocomplete="off"
                           aria-expanded="false"/>
                    <div id="search_autocomplete" class="search-autocomplete"></div>
                    <div class="nested">
    <a class="action advanced" href="https://trungdinh.com/catalogsearch/advanced/" data-action="advanced-search">
        Advanced Search    </a>
</div>
                </div>
            </div>
            <div class="actions">
                <button type="submit"
                    title="T&#xEC;m&#x20;ki&#x1EBF;m"
                    class="action search"
                    aria-label="Search"
                >
                    <span>Tìm kiếm</span>
                </button>
            </div>
        </form>
    </div>
</div>
<ul class="header-custom-block">
<li><a href="https://trungdinh.com/iphone-13" target="_blank" rel="noopener">iPhone 13</a></li>
<li><a href="https://trungdinh.com/samsung-galaxy-a" target="_blank" rel="noopener">Galaxy A Series</a></li>
<li><a href="https://trungdinh.com/apple-watch-series-7" target="_blank" rel="noopener">Apple Watch 7</a></li>
<li><a href="https://trungdinh.com/tablet" target="_blank" rel="noopener">Tablet</a></li>
</ul></div></div></header>    
<div class="sections nav-sections">
                <div class="section-items nav-sections-items"
             data-mage-init='{"tabs":{"openedState":"active"}}'>
                                            <div class="section-item-title nav-sections-item-title"
                     data-role="collapsible">
                    <a class="nav-sections-item-switch"
                       data-toggle="switch" href="#store.menu">
                        Menu                    </a>
                </div>
                <div class="section-item-content nav-sections-item-content"
                     id="store.menu"
                     data-role="content">
                    <div class="container">
    <div class="row">
        <div class="efom-megamenu-wrapper col-md-9">
            <nav class="efom-megamenu navigation p-0 m-0" role="navigation" data-action="navigation">
                <div id="defaultmenu" class="navbar-collapse collapse" v-if="this.miscData">
                    <ul class="nav navbar-nav" v-if="this.miscData.menus.main_menu">
                        <li class ="level0 dropdown category-item" v-for="mm in this.miscData.menus.main_menu.child">
                        	<i :class="mm.font_icon"></i><a class="level-top ui-corner-all" :href="mm.base_url" ><span>{{mm.name}}</span></a>
                        	<ul class="groupmenu-drop">
                        		<li class="sub-child" v-if="mm.menuitems">
                        			<ol>
										<li v-for="menuitem in mm.menuitems"><a :href="menuitem.link_url">{{menuitem.title}}</a></li>
									</ol>
								</li>
							</ul>
						</li>
					</ul>
                </div>
            </nav>
        </div>
	        <div class="menu-functions-wrapper col-md-3" v-if="this.miscData">
	        	<a v-for="menuitem in this.miscData.menus.info_menu.menuitems" :href="menuitem.link_url" class="menu-function trade-in float-right">
	                <span class="wrapper">
	                    <i :class="menuitem.font_icon"></i>
	                    <span class="title font-caption">{{menuitem.title}}</span>
	                </span>
	            </a>
	        </div>
    </div>
</div>                
</div>
</div>
</div>

<router-view/>

<footer class="page-footer">
<div class="footer content">
<div class="top-footer mt-16 container">
	<div class="row">
		<div class="store-system col-md-12">
            <div class="store-system-wrapper content-part">
                <p class="title bold fs-20 lh-23">Hệ thống 03 cửa hàng Di Động Trung Đỉnh</p><hr>
                <div class="content">
                    <div class="font-caption">Nơi có tất cả các sản phẩm Di Động Trung Đỉnh đang kinh doanh và là nơi Trade-in Thu cũ lên đời. Đặc biệt: Máy qua sử dụng giá rẻ, Trả góp linh hoạt với nhiều phương thức</div>
                    <div class="store-system-list">
                       <div class="showroom-info font-body-2">
<div class="field address">
<div class="showroom-info font-body-2">
<div class="field address">
<ul>
<li>580 Hậu Giang, Phường 12, Quận 6, TP.HCM. </li>
<li>1074 Hậu Giang, Phường 12, Quận 6, TP.HCM.</li>
<li>564 Hậu Giang, Phường 12, Quận 6, TP.HCM.</li>
</ul>
</div>
</div>
</div>
</div> 
                     </div><hr>
                    <a href="/pages/he-thong-cua-hang/">Xem tất cả cửa hàng Di Động Trung Đỉnh ></a>
                </div>
            </div>
        </div>
      </div>
<div class="widget block block-static-block" style="width:100%">
    <p class="ddv-apple-thongbao ddv-vertu-thongbao">
    	<img src="/assets/trungdinh/images/logo-trungdinh-pisen.png" alt=""/>Là đại lý ủy quyền PISEN tại Việt Nam, Di Động Trung Đỉnh đem đến những sản phẩm chất lượng, thời thượng, trải nghiệm mua sắm đẳng cấp xứng tầm phong cách sống thượng lưu.
    </p>
</div>
</div>
<div class="footer-middle">
<div class="bottom-footer mt-16 container">
    <div class="row">
        <div class="footer-links col-md-8">
            <div class="footer-link-wrapper content-part">
                <div class="footer-link footer-link-left" v-if="this.miscData">
                    <ul v-if="this.miscData.menus">
                    	
						<li v-for="menuitem in this.miscData.menus.about_menuitems"><a :href="menuitem.link_url">{{menuitem.title}}</a></li>
					</ul>                
				</div>
                <div class="footer-link footer-link-right" v-if="this.miscData">
                    <ul>
						<li v-for="menuitem in this.miscData.menus.customer_service_menu.menuitems"><a :href="menuitem.link_url">{{menuitem.title}}</a></li>
					</ul>                
				</div>
            </div>
        </div>
        <div class="footer-support col-md-4">
            <div class="footer-support-wrapper content-part">
                <p class="title bold fs-20 lh-23">Hỗ trợ</p>
                <div class="content">
                    <div class="support-list">
                        <ul>
                            <li>
                                <div class="agency">
                                    <p>Gọi tư vấn máy - phụ kiện</p>
                                    <span>(08:00 – 21:30)</span>
                                </div>
                                <div class="phone"><strong class="bold fs-20 lh-23">0916128128</strong></div>
                            </li>
                            <li>
                                <div class="agency">
                                    <p>Máy tính - Laptop PC</p>
                                    <span>(08:00 – 21:30)</span>
                                </div>
                                <div class="phone"><strong class="bold fs-20 lh-23">0916128128</strong></div>
                                <div class="phone"><strong class="bold fs-20 lh-23">0812798798</strong></div>
                            </li>
                            <li>
                                <div class="agency">
                                    <p>Khiếu nại - Góp ý</p>
                                    <span>(08:00 – 21:30)</span>
                                </div>
                                <div class="phone"><strong class="bold fs-20 lh-23">0828272727</strong></div>
                            </li>
                            <li>
                                <div class="agency">
                                    <p>Bảo hành - Hỗ trợ kỹ thuật</p>
                                    <span>(09:00 – 21:00)</span>
                                </div>
                                <div class="phone"><strong class="bold fs-20 lh-23">0903188128</strong></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-connect-info mt-16 container">
    <div class="row">
        <div class="col-md-12">
            <div class="connect-info-wrapper content-part">
                    <div class="footer-connect-left">
                        <div class="connect-info social">
                            <p class="title bold fs-20 lh-23">
                                <i class="fa fa-swap"></i>Kết nối Di Động Trung Đỉnh
                            </p>
                            <div class="icon">
                                <a rel="nofollow" target="_blank" href="https://www.facebook.com/didongviet/">
                                    <i class="fa fa-facebook"></i>
                                </a>
                                <a rel="nofollow" target="_blank" href="https://www.instagram.com/trungdinh.com/?hl=vi">
                                    <i class="fa fa-instagram"></i>
                                </a>
                                <a rel="nofollow" target="_blank" href="https://www.youtube.com/user/didongvietnam">
                                    <i class="fa fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                        <div class="connect-info pay-support">
                            <p class="title bold fs-20 lh-23">
                                <i class="fa fa-credit-card"></i>Hỗ trợ thanh toán                            </p>
                            <div class="icon">
                                <i class="fa fa-visa"></i>
                                <i class="fa fa-mastercard"></i>
                                <span class="more-payment"><img width="150" src="../assets/trungdinh/images/payment-more.png"/></span>
                            
                            </div>
                            <div class="cong-thuong">
                            	<a href="#" target="_blank" rel="nofollow" title="Chung nhan Tin Nhiem Mang">
                            		<img src="../assets/trungdinh/images/handle_cert.png" style="width:90px;" alt="Chung nhan Tin Nhiem Mang"/>
                            	</a>
                            	<a href="#" target="_blank" rel="nofollow" title="DMCA.com Protection Status" class="dmca-badge"> 
                            		<img src ="../assets/trungdinh/images/dmca_protected_16_120.png"  alt="DMCA.com Protection Status" />
                            	</a> 
                                <a rel="nofollow" target="_blank" href="#">
                                    <i class="fa fa-cong-thuong"></i>
                                </a>
                            </div>
                        </div>
</div>
            </div>
        </div>
    </div>
</div></div>
<div class="footer-bottom">
	<div class="footer-intro mt-16 container">
	    <div class=" ">
	        <div class="intro-content font-body-2 col-12">
	            <div class="group-textlinks" v-if="this.miscData">
					
					<div class="col-link col-link-1" v-for="child in this.miscData.menus.footer_menu.child" :key="child">
						<strong>{{child.name}}</strong>
						<ul>
							<li v-for="menuitem in child.menuitems"><a :href="menuitem.link_url">{{menuitem.title}}</a></li>
						</ul>
					</div>
				</div>        
			</div>
        	<div class="col-12"><hr></div>
        	<div class="info-content text-center font-body-2 col-12">
            	<p>Trung Đỉnh Mobile - 580 Hậu Giang, Phường 12, Quận 6, TP.HCM.<br>Chủ sở hữu: Trung Đỉnh - Điện thoại: 0903188128 (miễn phí) - Email: lienhe@trungdinh.com - Bản quyền thuộc về Trung Đỉnh.</p>        
            </div>
    	</div>
	</div>
</div>
</div>
</footer>
<div class="compare-popup" style="display: none;">
    <div class="close-compare"><i class="fa fa-times"></i></div>
    <div class="header-compare-wraper col-md-12">
        <div class="popup d-flex">
            <div class="title">Chọn sản phẩm so sánh</div>
                            <div class="compare-popup-sb_1 compare-popup__sb">
                    <div class="popup-input">
                        <div id="popup-remove_1"></div>
                        <span id="compare-popup-sb_1"></span>
                    </div>
                </div>
                                        <div class="compare-popup-sb_2 compare-popup__sb">
                    <div class="popup-input">
                        <div id="popup-remove_2"></div>
                        <span id="compare-popup-sb_2"></span>
                    </div>
                </div>
                                        <div class="compare-popup-sb_3 compare-popup__sb">
                    <div class="popup-input">
                        <div id="popup-remove_3"></div>
                        <span id="compare-popup-sb_3"></span>
                    </div>
                </div>
                    </div>
        <button class="btn-compare-show">So sánh</button>
    </div>
    <div class="product-compare_popup col-md-12">
        <div class="row header_item_info">
            <div class="col-md-3"></div>
                            <div class="col-md-3 compare__popup compare__popup__image__1">
                    <div id="compare__popup__image__1"></div>
                </div>
                                        <div class="col-md-3 compare__popup compare__popup__image__2">
                    <div id="compare__popup__image__2"></div>
                </div>
                                        <div class="col-md-3 compare__popup compare__popup__image__3">
                    <div id="compare__popup__image__3"></div>
                </div>
                    </div>
        <div class="pro__infomation">
            <div class="row">
            <div class="col-md-3 info-promotion title_promotion"><span class="title">Ưu đãi</span></div>
                                    <div class="col-md-3 info-promotion" id="compare__popup__promotion__1"></div>
                                                    <div class="col-md-3 info-promotion" id="compare__popup__promotion__2"></div>
                           
                                    <div class="col-md-3 info-promotion" id="compare__popup__promotion__3"></div>
                            </div>
            <div class="row">
                <div class="col-md-3 info-product title_manhinh"><span class="title">Màn hinh</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__manhinh_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__manhinh_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__manhinh_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_camerasau"><span class="title">Camera sau</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__camerasau_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__camerasau_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__camerasau_3"></div>
                            </div>
            <div class="row">
                <div class="col-md-3 info-product title_cameratruoc"><span class="title">Camera trước</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__cameratruoc_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__cameratruoc_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__cameratruoc_3"></div>
                            </div>
            <div class="row">
                <div class="col-md-3 info-product title_cpu"><span class="title">Hệ điều hành - CPU</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__cpu_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__cpu_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__cpu_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_bonho"><span class="title">Bộ nhớ - Lưu trữ</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__bonho_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__bonho_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__bonho_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_ketnoi"><span class="title">Kết nối</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__ketnoi_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__ketnoi_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__ketnoi_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_thietke"><span class="title">Thiết kế</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__thietke_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__thietke_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__thietke_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_pinsac"><span class="title">Thông tin pin sạc</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__pinsac_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__pinsac_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__pinsac_3"></div>
                            </div>
            <div class="row">
            <div class="col-md-3 info-product title_thongtinkhac"><span class="title">Thông tin khác</span></div>
                                    <div class="col-md-3 info-product" id="compare__popup__option__thongtinkhac_1"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__thongtinkhac_2"></div>
                                                    <div class="col-md-3 info-product" id="compare__popup__option__thongtinkhac_3"></div>
                            </div>
            <div class="row">
                <div class="col-md-3 info-product title_boxcart"></div>
                                    <div class="col-md-3" id="compare__popup__option__addtocart_1"></div>
                                                    <div class="col-md-3" id="compare__popup__option__addtocart_2"></div>
                                
                                    <div class="col-md-3" id="compare__popup__option__addtocart_3"></div>
                            </div>
        </div>
    </div>
</div>
    <div id="customer-popup-login" class="customer-popup-login">
        <div class="block block-customer-login">
            <div class="block-content" aria-labelledby="block-customer-popup-login-heading">
                <form class="form form-login"
                      action="https://trungdinh.com/customer/ajax/login/"
                      method="post"
                      id="customer-popup-login-form"
                      data-mage-init='{"validation":{}}'>
                    <input name="form_key" type="hidden" value="kmyFBXHmIh2br7d2">
                    <input type="hidden" name="redirect_url" value="https://trungdinh.com/" />
                    <fieldset class="fieldset login" data-hasrequired="* Required Fields">
                        <strong>Chào mừng trở lại</strong>
                        <div class="field note font-caption mt-8">Tạo một tài khoản để nhận được những ưu đãi tốt nhất từ Di Động Trung Đỉnh</div>
                        <div class="messages"></div>
                        <div class="field email required mt-16">
                            <label class="label" for="email"><span>Email</span></label>
                            <div class="control">
                                <input name="username" value=""  autocomplete="off" id="email-login" type="email" class="input-text font-subtitle-1" title="Email" data-validate="{required:true, 'validate-email':true}" placeholder="Email">
                            </div>
                        </div>
                        <div class="field password required mt-16">
                            <label for="pass" class="label"><span>Mật khẩu</span></label>
                            <div class="control">
                                <input name="password" type="password"  autocomplete="off" class="input-text font-subtitle-1" id="pass-login" title="Mật khẩu" data-validate="{required:true}" placeholder="Mật khẩu">
                            </div>
                        </div>
                        <div class="actions-toolbar mt-16">
                            <div class="primary"><button type="submit" class="action login primary" name="send" id="send2-login"><span>Đăng nhập</span></button></div>
                                                            <div class="secondary">
                                    <p class="forgot-title font-caption">Bạn quên mật khẩu?</p>
                                    <a class="action remind font-caption" href="https://trungdinh.com/customer/account/forgotpassword/">
                                        <p>Lấy lại mật khẩu</p>
                                    </a>
                                </div>
                                                    </div>
                        <!--Compatible with Mageplaza_SocialLogin-->
                            <div class="block social-login-authentication-channel account-social-login social-login mt-24">
        <p class="social-login-title font-caption">Hoặc đăng nhập bằng</p>
        <div class="block-content">
                            <div class="actions-toolbar social-btn social-btn-actions-toolbar social-icon google google-login">
                    <a class="btn btn-block btn-social btn-google"
                       data-mage-init='{"socialProvider": {"url": "https://trungdinh.com/sociallogin/social/login/type/google/", "label": "Login By Google"}}'>
                        <div class="icon"></div>
                    </a>
                </div>
                    </div>
    </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
    <div id="customer-popup-register" class="customer-popup-register">
        <div class="block block-customer-login">
            <div class="block-content" aria-labelledby="block-customer-popup-login-heading">
                <form class="form-create-account" action="https://trungdinh.com/ape/customer_ajax/register/" method="post" id="customer-popup-form-register" enctype="multipart/form-data" autocomplete="off" data-mage-init='{"validation":{}}'>
                    <input name="form_key" type="hidden" value="kmyFBXHmIh2br7d2">
                    <input type="hidden" name="redirect_url" value="https://trungdinh.com/" />
                    <input type="hidden" name="firstname" value="Customer">
                    <input type="hidden" name="lastname" value="New">
                    <div class="messages"></div>
                    <fieldset class="fieldset create account" data-hasrequired="* Required Fields">
                        <strong>Đăng ký tài khoản</strong>
                        <div class="field note font-caption mt-8">Tạo một tài khoản để nhận được những ưu đãi tốt nhất từ Di Động Trung Đỉnh</div>
                        <div class="field required mt-16">
                            <label for="popup-email_address" class="label"><span>Email</span></label>
                            <div class="control">
                                <input type="email" name="email" autocomplete="email" id="popup-email_address" value="" title="Email" class="input-text" data-validate="{required:true, 'validate-email':true}" placeholder="Email">
                            </div>
                        </div>
                        <div class="field password required mt-13" data-mage-init='{"passwordStrengthIndicator": {}}'>
                            <label for="password" class="label"><span>Mật khẩu</span></label>
                            <div class="control">
                                <input type="password" name="password" id="password"
                                       title="Mật khẩu"
                                       class="input-text"
                                       data-password-min-length="8"
                                       data-password-min-character-sets="3"
                                       data-validate="{required:true, 'validate-customer-password':true}"
                                       autocomplete="off" placeholder="Mật khẩu">
                                <div id="password-strength-meter-container" data-role="password-strength-meter" >
                                    <div id="password-strength-meter" class="password-strength-meter"></div>
                                </div>
                            </div>
                        </div>
                        <div class="field confirmation required mt-13">
                            <label for="password-confirmation" class="label"><span>Xác nhận mật khẩu</span></label>
                            <div class="control">
                                <input type="password" name="password_confirmation" title="Xác nhận mật khẩu" id="password-confirmation" class="input-text" data-validate="{required:true, equalTo:'#password'}" autocomplete="off" placeholder="Xác nhận mật khẩu">
                            </div>
                        </div>
                        <div class="actions-toolbar mt-16">
                            <div class="primary">
                                <button type="submit" class="action submit primary" title="Đăng ký"><span>Đăng ký</span></button>
                            </div>
                            <div class="secondary">
                                <p class="forgot-title font-caption">Bạn đã có tài khoản?</p>
                                <a class="action remind font-caption" href="#" id="customer-popup-sign-in">
                                    <p>Đăng nhập</p>
                                </a>
                            </div>
                        </div>
                        <div class="social-login mt-24">
                            <p class="social-login-title font-caption">Hoặc đăng ký bằng</p>
                            <!-- <div class="social-icon facebook"></div> -->
                            <div class="social-icon google"></div>
                            <!-- <div class="social-icon apple"></div> -->
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>


</div> 
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import Toast from '@/components/Toast.vue';
import axios from 'axios'
import config from '@/config.js';
import moment from 'moment';
export default {
  name: "LayoutDefault",
  components: {
  	Toast,
  	Carousel,
  },
  data() {
    return {
      showMobileMenu: false,
      cart: {},
      isWalletConnected:false,
      isAuthenticated:false,
      accessToken:"",
      refreshToken:"",
      copyright_year: moment(new Date()).format('YYYY'),
      site_name:"Trung Đỉnh",
      miscData:null,
      services:null,
      items:null,
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    
    const token = this.$store.state.token
    if (token) {
        //axios.defaults.headers.common['Authorization'] = "Token " + token
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  async created() {
      const rs = await axios
        .get('/api/misc-data/')
        .then(response => {
          this.$store.commit('setMiscData', response.data)
          this.miscData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  mounted() {
  	this.cart = JSON.parse(localStorage.getItem("cart"));
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    this.refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
    if (this.accessToken) {
    	//verifyToken(this.accessToken,this.refreshToken);
    }
  	this.isWalletConnected = JSON.parse(localStorage.getItem("isWalletConnected"));
  	this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
  	this.getCoinbase()
    this.cart = this.$store.state.cart
    //this.miscData = JSON.parse(localStorage.getItem("miscData"))
   },
  methods: {
    async signup() {
    		axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            
    		const formData = {
            	user: {
			        full_name: accounts[0],
			    },
                public_address: accounts[0]
            }

            await axios
                .post("/api/metamask/", formData)
                .then(response => {
                    $('#connectWalletModal').modal('toggle');
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
					
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	
    	async login(nonce) {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            let msg = 'I am signing my one-time nonce '+nonce
            let sign = await web3.eth.personal.sign(msg, public_address);
            
            //const sign=web3.personal.sign(web3.utils.toUtf8("Hello!"), accounts[0], console.log);
            //console.log(sign)
            
            const formData = {
            	signature:sign,
            }
			
            await axios
                .post("/api/metamask/login/"+public_address, formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    $('#connectWalletModal').modal('toggle');
                    
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
					axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
                    this.$store.commit('setRefreshToken',response.data.refresh)
                    this.$store.commit('setAccessToken',response.data.access)
                    
                    this.isWalletConnected = true;
                    this.$store.state.user = response.data.user
                    localStorage.setItem("token", token)
					this.$store.state.isWalletConnected=true;
					
                    
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                	this.signup()
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
	async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
    },
    async startLogin() {
    const publicAddress =this.metamask_address;
    
     if (window.ethereum) {
	  try {
	    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	    if(accounts.length >0) {
	    		$('#connectWalletModal').modal('toggle');
	    		//localStorage.setItem("isWalletConnected",true);
	    		//this.$store.commit('setIsWalletConnected',true);
		        this.$store.commit('setIsWalletConnected',true);
				this.$store.commit('setIsAuthenticated',true);
				this.$store.commit('setUser',response.data.user)
				
	    	}
	  } catch (error) {
	    if (error.code === 4001) {
	      // User rejected request
	    }
	
	    console.error(error);
	  }
	  }
	},
    async disconnectWallet() {
    	console.log(web3);
    	console.log(web3.currentProvider);
		this.$store.commit('setIsWalletConnected',false);
		this.$store.commit('setIsAuthenticated',false);
		this.$store.commit('removeToken','');
		
		this.$store.commit('setMetamaskWallet',{});
		axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("token");
            
		/*
		await window.ethereum.request({
	        method: "wallet_requestPermissions",
	        params: [{
	            eth_accounts: {}
	        }]
	    });
	    */
    },    
    async connectWallet() {
	      try {
	        await ethereum.enable();
	        $('#connectWalletModal').modal('toggle');
			
			this.$store.commit('setIsWalletConnected',true);
			this.$store.commit('setIsAuthenticated',true);
			
	      } catch (error) {
	      	this.$store.commit('setIsWalletConnected',false);
			this.$store.commit('setIsAuthenticated',false);
	        this.Log(
	          this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION,
	          "USER_DENIED_ACCOUNT_AUTHORIZATION"
	        );
	      }
	      
    },
  	
  	hideToast() {
  		this.$store.commit("hideToast");
  	},
	async getItemList() {
	  		await axios
	                .get("/api/items/?image_sizes=550x590")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTrendingArtworks', response.data.results);
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
  	async getCoinbase() {
    	await axios
                .get("https://api.coinbase.com/v2/exchange-rates?currency=MATIC")
                .then(response => {
					this.$store.commit('setRate', response.data.data);
					console.log(response.data.data.currency);
					console.log(response.data.data.rates);
					console.log(response.data.data.rates['ETH']);
					console.log(response.data.data.rates['BTC']);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  		console.log(localStorage.getItem("token"))
  		axios.defaults.headers.common["Authorization"] = "Token "+localStorage.getItem("token")
  		await axios
                .post("/api/v1/token/logout/", {})
                .then(response => {
                    
                    
			
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		            this.$router.push('/')
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
  	toast() {
  			return this.$store.getters.toast;
  		},
      cartTotalLength() {
          let totalLength = 0
		   console.log(this.cart);
		   
          for (let i = 0; i < this.cart.items; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>
